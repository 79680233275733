@import "../../../../scss/index.scss";

.forgot-password {
	padding: 2rem 0;
    width: 375px;
    @media screen and (min-width: $media-medium) {
        padding: 2rem;
    }
	.forgot-password-form {
		.link-forgotPass {
			@include flexbox(auto, row, flex-end, center);
			font-size: 14px;
			margin-bottom: 2rem;
			span{
				&:hover {
					color: $wild-willow; 
				}
			}
		}

		.button-container{
			@include flexbox(auto, row, center, center);
		}

		.pointer {
			cursor: pointer;
		}
	}
}
