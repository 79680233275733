@import "../../scss/index.scss";

.translationButton {
    .translate-wrapper {
        @include flexbox(auto, row, flex-start, center,$wr:nowrap);
        cursor: pointer;
        span:first-of-type {
            display: none;
            font-weight: bold;
            font-size: .875rem;
            @media all and (min-width: $media-large) {
                display: block;
            }
        }
    }
}
.flag-img {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
}
