@import '../../../../scss/index.scss';

.evaluator-container{
    @include flexbox(100%, row, center, center);
    margin-top: 15px;
    
    .evaluator-wrapper {
        .input-container {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            width: 100% !important;
            padding: 0;
            input {
                width: 75px;
            }
            .material-icons {
                top: 10px;
                right: 14px;
                background-color: $chatams-blue;
                color: $color-white;
                font-size: 16px;
                border-radius: 3px;
                width: 16px;
                height: 16px;
            }
        }
    }
}