@import "../../scss/index.scss";
.menu-left {
    &.aside{
        width: 100%;
        background-color: $chatams-blue;
        z-index: 10;
        @media screen and (min-width: $media-medium) {
            width: 250px;
            height: 100%;
            padding-top: 1rem;
            position: fixed;
        }
        

        .logo-container{

            .logo-wrapper{
                width: 250px;
                margin: 1rem auto;
                margin-bottom: 0;
                padding-top: 1rem;
                @media screen and (min-width: $media-medium) {
                    width: auto;
                    margin: inherit;
                }
            }
        }
    }
    &-list {
        box-shadow: 0px 2px 0px $chatams-blue;
        @media screen and (min-width: $media-medium) {
            margin-top: 1rem;
            box-shadow: none;
        }

        &__item {
            cursor: pointer;
            padding: 0 1rem;
            a {
                padding: 16px 16px 16px 16px;
                display: block;
                color: $white;
                font-size: 16px;
                text-decoration: none !important;
                .anchor-item{
                    display: flex;
                    align-items: center;
                    span:first-of-type{
                        margin-right: .5rem;
                    }
                }
            }
            &:hover {
                text-decoration: none !important;
                background-color: $monza-normal;
             
                a,
                span:first-of-type {
                    text-decoration: none !important;
                    color: $white;
                }
            }
            &:active {
                text-decoration: none !important;
                background-color: $monza-dark;
                a,
                span:first-of-type {
                    text-decoration: none !important;
                    color: $white;
                }
            }
            &.active {
                background-color: White;
                font-weight: bolder;
                a,
                span:first-of-type {
                    color: $chatams-blue;
                }
            }
        }
        .mini-menu {
            color: $white;
            font-size: 20px;
            @include flexbox(100%, row, center, center);
            margin-bottom: 1rem;
            cursor: pointer;
            .icon {
                padding: 0px 10px;
            }
            .icon-float-left {
                margin-top: 0.5rem;
                display: inline-block;
            }
            @media screen and (min-width: $media-medium) {
                display: none;
            }
        }
    }
    .display-none {
        display: none;
        @media screen and (min-width: $media-medium) {
            display: block;
        }
    }
    .display {
        display: block;
    }
}
