@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;700&display=swap');
/*PROJECT COLORS*/
:root {
    --color-primary: #748781;
    --color-primary-variant: #a4aaa6;
    --color-primary-variant2: #eae9e3;
    --color-primary-disabled: #d2d6d6;
    --color-primary-pressed: #474e4c;
    --color-primary-hover: #919b99;
    --color-secondary: #9a817c;
    --color-secondary-variant: #706f70;
    --color-secondary-variant2: #d7d4ce;
    --color-text: #595959;
    --color-error: #95260e;
    --color-error-bg: #ccaca6;
    --color-warning: #d9d2aa;
    --color-warning-bg: #dad3a8;
    --color-success: #b6c3be;
    --color-success-bg: #b2c4be;
    --color-menu-bg: #f1f1f1;
    --color-menu: #000;
    --color-header-bg: #ffffff;
    --color-header: #000;
    --color-main-bg: #ffffff;
}

$color-primary: var(--color-primary);
$color-primary-variant: var(--color-primary-variant);
$color-primary-variant2: var(--color-primary-variant2);

$color-secondary: var(--color-secondary);
$color-secondary-variant: var(--color-secondary-variant);
$color-secondary-variant2: var(--color-secondary-variant2);

$color-primary-disabled: var(--color-primary-disabled);
$color-primary-pressed: var(--color-primary-pressed);
$color-primary-hover: var(--color-primary-hover);

$color-secondary-disabled: "";
$color-secondary-pressed: "";
$color-secondary-hover: "";

$color-text: var(--color-text);
$color-error: var(--color-error);
$color-error-bg: var(--color-error-bg);
$color-warning: var(--color-warning);
$color-warning-bg: var(--color-warning-bg);
$color-success: var(--color-success);
$color-success-bg: var(--color-success-bg);

$color-menu-bg: var(--color-menu-bg);
$color-menu: var(--color-menu);
$color-header-bg: var(--color-header-bg);
$color-header: var(--color-header);
$color-main-bg: var(--color-main-bg);
/***/

/* OTHER COLORS */
$color-black: #000000;
$color-gray: #606060;
$color-gray-clear: #a2a2a2;
$color-disabled: #8f8f8f;
$color-disabled-clear: rgba(66, 66, 66, 0.2);
$color-disabled-dark: #424242;
$color-shadow: rgba(143, 143, 143, 0.5);
$color-white: #fff;
$color-white-clear: #f2f2f2;
$color-red: #f5454b;
$color-red-clear: #f76a6f;
$color-red-dark: #df2e34;
$color-border_red: #f84346;
$color-border_red-dark: #e12b2d;
$color-blue-dark-cold: #575f70;
$color-green: #508745;
$color-green-clear: #79b470;
$color-green-dark: #46763c;
$color-light-green: #aed1a9;
$color-light-green-clear: #deecdc;
$color-light-green-dark: #cde3ca;
$color-orange: #ffa400;
$color-orange-clear: #ffb640;
$color-orange-dark: #ff9500;
$color-bg-sand: #fff3de;
$color-blue-dark: #071e4f;
$color-blue-clear: #294785;
$toryblue: #1547bc;
$color-bg-sand-dark: #ffe0ae;

//Madrid excelente
$chatams-blue: #104177;
$monza-normal: #DC0513;
$monza-dark: #C10411;
$monza-light: #c104113b;
$bali-hai: #8395AA;
$wild-willow: #ABD268;
$energy-yellow: #F8D750;
$eastern-blue: #28ABB0;
$athens-gray: #EDEFF2;
$silver: #B8B8B8;
$gallery: #EDEDED;
$pot-pourri: #F7E5E5;
$mine-shaft: #313131;
$scorpion: #595959;
$white: #ffffff;
$focus-row: #7eacdd;



$box-shadow: 0 2px 4px 0 $color-shadow;
$border-default: 1px solid $color-green;

$media-xx-large: 1600px; //1400px
$media-large-land: 1400px; //1366px
$media-x-large: 1366px; //1200px
$media-medium-land: 1200px; //1024px
$media-large: 1024px; //992px
$media-medium: 992px; //768px
$media-small-land: 768px; //640px
$media-small: 640px; //576px
$media-x-small: 576px; //450px

$font: 'Urbanist', sans-serif;


//Calendar EVENTS
$out-of-work: #a4a8f9;
$holidays: #ffad61;
$meeting: #eb93ac;
$font-size: 16;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
$font-size-h1: $font-size-base * 2.5; //40px
$font-size-h2: $font-size-base * 2; //32
$font-size-h3: $font-size-base * 1.75; //28
$font-size-h4: $font-size-base * 1.5; //24
$font-size-h5: $font-size-base * 1.25; //20
$font-size-h6: $font-size-base;
$sidebarWidth: 365;
