@import "../../../scss/index.scss";

.cookie-config-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;

    &__wrapper {
        min-width: 400px;
        max-width: 900px;
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    
        &__content {
            max-height: 60vh;
            overflow: auto;
            padding: 5px;
    
            .cookie-config-modal__title {
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 10px;
            }
    
            .cookie-config-modal__message {
                font-size: 12px;
                margin-bottom: 20px;
            }
    
            .e-accordion {
                border: none;
                .e-acrdn-item {
                    border: none !important;
    
                    &.e-select {
                        background: transparent;
                        border-radius: 5px;
                        margin-bottom: 5px;
                    }
                    &.e-select.e-expand-state > .e-acrdn-header {
                        background-color: #f2f2f2 !important;
                        
                        .e-acrdn-header-content {
                            color: inherit !important;
                            background-color: #f2f2f2;
                        }
                    }
    
                    .e-acrdn-header {
                        width: 100%;
                        border-radius: 5px;
                        background-color: #f2f2f2;
                        line-height: 25px;
    
                        &:hover {
                            background-color: #f2f2f2;
                        }
    
                        .e-acrdn-header-content {
                            width: 100%;
                            font-size: 14px;
                            padding: 10px;
                            cursor: pointer;
                            background-color: #f2f2f2;
                            transition: background-color 0.3s ease;
    
                            &:hover {
                                background-color: #f2f2f2;
                            }
    
                            & > div {
                                width: 100%;
    
                                .item-header {
                                    @include flexbox();
    
                                    &__right {
                                        display: flex;
                                        align-items: center;
                                        gap: 15px;
    
                                        .e-switch-wrapper {
                                            .e-switch-handle {
                                                &.e-switch-active {                                                
                                                    background-color: $color-green;
                                                }
                                            }
                                            .e-switch-inner {
                                                &.e-switch-active {
                                                    .e-switch-on {
                                                        background-color: $color-green-clear;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                    .item-header__action {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
    
                        .e-toggle-icon {
                            top: 5px;
                        }
                    }
    
                    .e-acrdn-panel {
                        .e-acrdn-content {
                            border: none;
                            background-color: white;
        
                            .item-content {
                                p {
                                    margin-bottom: 10px;
                                }
    
                                .cookies-modal-table {
                                    visibility: visible;
                                    text-align: left;
                                    pointer-events: auto;
                                    box-sizing: border-box;
                                    border: 0;
                                    vertical-align: baseline;
                                    word-wrap: break-word;
                                    table-layout: fixed;
                                    padding: 0;
                                    margin: 10px 0 20px;
                                    border-collapse: collapse;
                                    color: #333;
                                    background: #F3F5F7;
                                    width: 100%;
                                    margin-left: 0;
                                    font-size: 12px;
    
                                    thead {
                                        tr {
    
                                            th {
                                                width: 50%;
                                                background: #3A4856;
                                                padding: 15px 10px;
                                                color: #fff;
                                                text-align: left;
                                                font-weight: normal;
                                                font-size: 12px;
                                            }
                                        }
                                    }
    
                                    tbody {
                                        tr {
                                            &:hover {
                                                background: #EAECEE;
                                                color: #111;
                                            }
    
                                            td {
                                                font-weight: 400;
                                                width: 15%;
                                                padding: 10px;
                                                text-align: left;
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .e-acrdn-item:not(:last-child) {
                    .e-acrdn-header-content {
                        border-bottom: none;
                    }
                }
            }
        }
    
        &__buttons {
            margin-top: 5px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
