@import "../../scss/index.scss";

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    .footer-wrapper {
        @include flexbox(auto, column, center, center, nowrap);
        padding: 1rem 0;

        @media screen and (min-width: 900px) {
            flex-direction: row;
        }

        .footer-logo-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            @media screen and (min-width: 900px) {
                width: calc(100vw - 340px);
                padding-left: 340px;
            }

            .footer-slogan{
                display: inline-block;
                padding-right: .5rem;
                margin-top: 0.5rem;
                font-size: 14px;

                &.hide-logo {
                    display: none;
                }
            }
            .footer-logo{
                width: 100px;
                height: auto;
                padding-left: .5rem;
                object-fit: contain;

                &.hide-logo {
                    display: none;
                }
            }
        }
        .footer-links {
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            @media screen and (min-width: 900px) {
                width: 340px;
            }

            a {
                text-decoration: none;
                display: inline-block;
                padding-right: 0.5rem;
                margin-top: 0.5rem;
                font-size: 14px;
                color: inherit;
            }
        }
    }
}
