@import "@backoffice/scss/index.scss";

.btn-base {
    @include btn($fontWeight: bold);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    &_outline {
        @extend .btn-base;
        background-color: transparent;
        color: $color-primary;
        border: 1px solid $color-primary;
        &:hover {
            border: 1px solid $color-primary;
            //color: $color-primary;
        }
        &:disabled {
            border-color: $color-primary-disabled;
            color: $color-primary-disabled;
            cursor: not-allowed;
        }
    }
    &_outline-clear {
        @extend .btn-base_outline;
        border-width: 0;
        &:hover {
            border-width: 0;
            color: $color-gray;
        }
        &:disabled {
            border-width: 0;
            color: $color-disabled;
            cursor: not-allowed;
        }
    }
    &_normal {
        @extend .btn-base;
        &:hover {
            background-color: $monza-normal; 
        }
        &:active {
            background-color: $monza-dark; 
        }
        &:focus {
            //background-color: $color-primary-pressed;
        }
        &:disabled {
            background: $color-primary-disabled;
            cursor: not-allowed;
            border: transparent;
            color: $color-white;
        }
        &.btn-base_outline{
            color: $chatams-blue;
            border-color: $chatams-blue;
            &:hover {
                background-color: $monza-normal; 
                border-color: transparent;
                color: $white;
            }
            &:active {
                background-color: $monza-dark; 
            }
            &:focus {
                //background-color: $color-primary-pressed;
            }
            &:disabled {
                background: $color-primary-disabled;
                cursor: not-allowed;
                border: transparent;
                color: $color-white;
            }
        }
    }
    &_outline-normal {
        @extend .btn-base_normal;
        background-color: transparent;
        $color: $color-primary;
        border: 1px solid #cddeff;
        &:hover {
            $color: $color-primary-hover;
            background-color: #676fa3;
        }
        &:focus {
            $color: $color-primary-pressed;
            background-color: $color-white;
        }
        &:active {
            $color: $color-primary;
            background-color: transparent;
        }
        &:disabled {
            cursor: not-allowed;
            border-color: $color-primary-disabled;
            color: $color-white;
        }
    }
    &_primary {
        @extend .btn-base_normal;
        background-color: $wild-willow;
        &:hover {
            background-color: $eastern-blue;
        }
        &:focus {
            background-color: $eastern-blue;
        }
        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_outline-primary {
        @extend .btn-base_primary;
        color: $color-orange-clear;
        background-color: $color-white;
        border: 1px solid $color-orange-clear;
        &:hover {
            color: $color-white;
            background-color: $color-orange-dark;
        }
        &:focus {
            color: $color-white;
            background-color: $color-orange-dark;
        }
        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_secondary {
        @extend .btn-base;
        background-color: $color-disabled;
        color: $color-white;
        &:hover {
            background-color: $color-disabled-dark;
        }
        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            border: transparent;
            color: #ffffff;
        }
    }
    &_outline-secondary {
        @extend .btn-base_secondary;
        color: $monza-normal;
        background-color: $color-white;
        border: 1px solid $monza-normal;

        &:hover {
            color: $color-white;
            background-color: $monza-normal;
        }
        &:disabled {
            background: $color-disabled;
            border: 1px solid $color-disabled;
            cursor: not-allowed;
            color: $color-disabled;
        }
    }
    &_danger {
        @extend .btn-base;
        color: $color-white-clear;
        background-color: $monza-dark;
        &:hover {
            background-color: $color-red;
        }
        &:focus {
            background-color: $color-red;
        }
        &:active {
            background-color: $color-red;
        }
        &:disabled {
            background: $color-disabled;
            border: 1px solid $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_outline-danger {
        @extend .btn-base_danger;
        color: $monza-dark;
        border: 1px solid $monza-dark;
        background-color: $color-white;
        &:hover {
            color: $color-white;
            border-color: $color-white;
            background-color: $color-red-clear;
        }
        &:focus {
            color: $color-red-clear;
            border-color: $color-red-clear;
            background-color: $color-white;
        }
        &:active {
            color: $color-red-clear;
            border-color: $color-red-clear;
            background-color: $color-white;
        }
    }
    &_success {
        @extend .btn-base;
        background-color: $wild-willow;
        &:hover {
            background-color: $color-green-dark;
        }
        &:focus {
            background-color: $color-green-dark;
        }
        &:active {
            background-color: $color-green-dark;
        }
        &:disabled {
            background: $color-disabled;
            border: 1px solid $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_outline-success {
        @extend .btn-base_success;
        color: $wild-willow;
        border: 1px solid $wild-willow;
        background-color: $color-white;
        &:hover {
            color: $color-white;
            border-color: $color-white;
            background-color: $wild-willow;
        }
        &:focus {
            color: $wild-willow;
            border-color: $wild-willow;
            background-color: $color-white;
        }
        &:active {
            color: $wild-willow;
            border-color: $wild-willow;
            background-color: $color-white;
        }
    }
    &_warning {
        @extend .btn-base;
        color: $color-black;
        background-color: $color-orange-clear;
        &:hover {
            background-color: $color-orange-dark;
        }
        &:focus {
            background-color: $color-orange-dark;
        }
        &:active {
            background-color: $color-orange-dark;
        }
        &:disabled {
            background: $color-disabled;
            border: 1px solid $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_outline-warning {
        @extend .btn-base_warning;
        color: $color-orange-clear;
        border: 1px solid $color-orange-clear;
        background-color: $color-black;
        &:hover {
            color: $color-black;
            border-color: $color-black;
            background-color: $color-orange-clear;
        }
        &:focus {
            color: $color-orange-clear;
            border-color: $color-orange-clear;
            background-color: $color-black;
        }
        &:active {
            color: $color-orange-clear;
            border-color: $color-orange-clear;
            background-color: $color-black;
        }
    }
}
//Sidebar filters
.btn-container {
    @include flexbox(100%, row, center, center);
    margin-bottom: 10px;
    position: relative;

    & > button {
        width: 100%;
        border-width: 0;
    }
    .material-icons {
        @media only screen and (min-width: $media-x-small) {
            position: absolute;
            left: 39%;
            top: 7px;
        }

        @media only screen and (min-width: $media-medium) {
            position: absolute;
            left: 39%;
            top: 7px;
        }

        @media only screen and (min-width: $media-large) {
            position: absolute;
            left: 39%;
            top: 5px;
        }

        @media only screen and (min-width: $media-medium-land) {
            position: absolute;
            left: 60px;
        }
    }
}
.btn-group-row {
    button {
        margin-right: 1em;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

//New btn styles

.btn-base {
    &.outline-normal {
        @extend .btn-base_normal;
        background-color: transparent;
        color: $color-green;
        border: 1.5px solid $color-green-dark;
        height: 32px;
        &:hover {
            color: $color-green-dark;
            background-color: $color-white;
        }
        &:focus {
            color: $color-green-dark;
            background-color: $color-white;
        }
        &:active {
            color: $color-green-dark;
            background-color: transparent;
        }
        &:disabled {
            cursor: not-allowed;
            border-color: $color-disabled;
            color: $color-white;
        }
    }
}
