@import "../../../scss/index.scss";

.input-container {
    @include flexbox(auto, column, flex-start, flex-start);
    position: relative;
    padding-bottom: .3em;

    .floating-label {
        position: absolute;
        pointer-events: none;
        left: 9px;
        top: 9px;
        transition: 0.2s ease all;
        opacity: 0;
        font-size: 13px;
        color: $color-gray-clear;
    }

    .inputLabel {
        margin: 2px 6px 6px 0;
        padding-left: 0;
        color: $color-secondary-variant;
        //font-weight: 600;
        font-size: 12px;
    }

    .input-control {
        width: 100%;
        height: 37px;
        //border-radius: 16px;
        border: transparent;
        padding: 0.25em 0.6em;
        box-sizing: border-box;
        font-size: 14px;
        color: $color-text;
        background-color: $athens-gray;

        &:read-only {
            background-color: $athens-gray !important;
            color: #989393;
        }

        &.left {
            padding-left: 2.25rem;
        }

        &:focus {
            border: 1px solid $silver;
            outline: none !important;
        }
        &:focus-visible {
            border: 1px solid $silver;
            outline: none !important;
        }
        
        &.input-error {
            //border: 2px solid $color-error;
            background-color: $pot-pourri;
            color: $monza-normal;
            margin-bottom: 1rem;
        }

        &:focus ~ .floating-label, &:not(:focus):valid ~ .floating-label, & ~ .floating-label.floating-disabled {
            left: 9px;
            top: -20px;
            opacity: 1;
        }

        &:focus::placeholder {
            opacity: 0;
        }

        // &.input-outline {
        //     border: 1px solid $color-gray-clear;
        //     &:focus {
        //         border-width: 2px;
        //         border-color: $color-secondary;
        //     }
        //     &:focus-visible {
        //         border: 2px solid $color-secondary;
        //     }
        //     &.input-success {
        //         border-color: $color-green-dark;
        //     }
        //     &.input-error {
        //         border: 2px solid $color-error;
        //     }
        // }
    }

    .material-icons {
        position: absolute;
        top: 0.4rem;
        color: $chatams-blue;
        font-size: 1.5rem;
        &.left {
            left: 0.5rem;
        }
        &.right {
            right: 0.5rem;
        }
    }
    .error {
        color: $monza-normal;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .valid {
        color: $color-green-clear;
        position: absolute;
        font-weight: bold;
        bottom: 10px;
        left: 10px;
        font-size: 14px;
    }

    .e-time-wrapper {
        width: 100% !important;
        height: 32px !important;
        border-radius: 45px !important;
        border: none !important;
        padding: 0 1em !important;
        box-sizing: border-box !important;
        border: 1px solid $color-gray-clear !important;

        &:focus {
            border-color: $color-secondary !important;
        }
        &.e-input-focus {
            border: 1.5px solid $color-secondary !important;
        }

        &.input-error {
            border-color: $color-secondary !important;
        }

        .e-time-icon {
            color: $color-secondary !important;
        }
    }
}

.e-input-focus {
    &::after {
        background: transparent !important;
    }
    &::before {
        background: transparent !important;
    }
}

.e-ul {
    .e-list-item {
        &.e-hover {
            color: black !important;
        }
        &.e-active {
            color: $color-secondary !important;
        }
        &:hover {
            background-color: $color-secondary !important;
            color: white !important;
        }
    }
}

.e-input::selection {
    color: black !important;
    background-color: transparent !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: -0.5rem;
    font-weight: bold;
    font-size: 18px;
    filter: invert(38%) sepia(22%) saturate(952%) hue-rotate(64deg) brightness(100%) contrast(89%);
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
}

::-webkit-input-placeholder {
    color: $color-gray-clear !important;
    font-size: 14px;
    transition: 0.2s ease all;
}

:-ms-input-placeholder {
    color: $color-gray-clear !important;
    font-size: 14px;
    transition: 0.2s ease all;
}

::placeholder {
    color: $color-gray-clear !important;
    font-size: 14px;
    transition: 0.2s ease all;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.e-input-group.e-control-wrapper.e-time-wrapper::after,
.e-input-group.e-control-wrapper.e-time-wrapper::before {
    background: transparent !important;
}

input:-internal-autofill-selected {
    background-color: none;
}
