@import "../../../../scss/index.scss";

.companies-info-detail {
    width: 100%;

    &-filters {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        gap: 1rem;
        flex-wrap: wrap;
        
        .combo-wrapper,
        .inputAutoSuggest, 
        .input-wrapper,
        button {
            width: 100%;
            margin-bottom: 0;
        }

        button {            
            margin-bottom: 0.3rem;
        }

        @media screen and (min-width: $media-medium-land) {
            flex-direction: row;

            .combo-wrapper, 
            .inputAutoSuggest,
            .input-wrapper,
            button {
                width: 49%;
            }
        }
    }

    .error-message {
        text-align: center;
        padding: 1rem;
        font-size: 14px;
        font-style: italic;
        color: $monza-normal;
    }

    .recent-detail {
        background-color: $focus-row;
        td {
            color: $color-white !important;
        }
    }

    &-table-title {
        margin-top: 50px;
    }
}