@import '../../../../scss/index.scss';

.radio-wrapper{
    width: auto;
    margin-left: 1rem;
    &:first-of-type{
        margin-left: 0;
    }
    
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $mine-shaft;
    }
    [type="radio"]:checked:disabled + label,
    [type="radio"]:not(:checked):disabled + label{
        cursor: default !important;
    }
    [type="radio"]:checked + label:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        border: 2px solid $chatams-blue;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        //border: 2px solid $chatams-blue;
        border-radius: 100%;
        background: $athens-gray;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: $chatams-blue;
        position: absolute;
        top: 6px;
        left: 6px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    label{
        span{
            font-style: italic !important;
        }
    }
}


.radio-wrapper [type=radio]:checked:disabled + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 2px solid #c9ccd1;
    border-radius: 100%;
    background: #fff;
}
.radio-wrapper [type=radio]:checked:disabled + label:after, .radio-wrapper [type=radio]:not(:checked):disabled + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #c9ccd1;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    transition: all 0.2s ease;
}