@import "../../scss/index.scss";

.cookie-consent-container {
    background: $color-white !important;
    color: $color-text !important;
    left: 50% !important;
    width: 90% !important;
    top: 50% !important;
    bottom: inherit !important;
    transform: translate(-50%, -50%) !important;
    font-size: 14px;
    line-height: 24px;

    @media screen and (min-width: $media-small) {
        width: 500px !important;
    }

    .cookie-consent-content {
        margin: 20px !important;
        text-align: justify;
    }

    .cookie-consent-buttons {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .btn-decline-cookie-consent,
        .btn-accept-cookie-consent {
            margin: 10px !important;
        }

        .btn-decline-cookie-consent {
            color: $color-text !important;
            background-color: $color-white !important;
        }

        .btn-accept-cookie-consent {
            color: $color-white !important;
            background-color: #e0141d !important;
            border-radius: 4px !important;

            &:hover {
                background-color: #b31017 !important;
            }
        }
    }
}