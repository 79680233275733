@import "@backoffice/scss/index.scss";

.login {
    @include flexbox(auto, column, center, center, nowrap);
    height: 100vh;
    box-sizing: border-box;
    padding: 10px;
    
    @media screen and (min-width: $media-x-small) {
        padding: 20px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (min-width: $media-medium) {
        flex-direction: row;
    }
    .login-wrapper{
        &:last-of-type{
            width: 100%;
            border-top: 1px solid $mine-shaft;
            @media screen and (min-width: $media-medium) {
                border: none;
            }
        }
        .img-wrapper {
            width: auto;
            font-size: inherit;
            @media screen and (min-width: $media-medium) {
                width: 375px;
            }
            .logo {
                width: 100%;
                height: auto;
            }
        }
    }
    .separator{
        border-left: 1px solid $mine-shaft;
        height: 375px;
        display: none;
        @media screen and (min-width: $media-medium) {
            display: block;
        }
    }
}
