@import '../../../scss/index.scss';

.request{
    margin-bottom: 6rem;

    @media screen and (min-width: 900px) {
        margin-bottom: 4rem;
    }

    .request-container{
        .link{
            color: #dc0513;
            &:hover{
                color: #104177;
                text-decoration: none;
                border-bottom: 1px solid;
            }
        }
        .logo-send-success{
            width: 100%;
            text-align: center;
            span{
                font-size: 6rem;
                color: #104177;
            }
        }
        .request-header{
            margin-bottom: 2rem;
            @media screen and (min-width: $media-medium) {
                margin-bottom: 4rem;
            }
            .logome-wrapper{
                position: absolute;
                left: 5%;
                top: 10px;
                width: 300px;
            }
            .hero-wrapper{
                width: 100%;
                height: 450px;
                .hero-img{
                    object-fit: cover;
                    object-position: 50% 10%;
                    width: 100%;
                    height: 450px;
                }
            }
            .help-wrapper{
                position: absolute;
                top: 1rem;
                right: 5%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex-direction: column;
                color: $chatams-blue;
                @media screen and (min-width: $media-small-land) {
                    align-items: center;
                    flex-direction: row;
                }

                
                .icon{
                    font-size: 2rem;
                    display: inline-block;
                    cursor: pointer;
                    transition: all .2s ease;
                    padding: 0;
                    color: $chatams-blue;
                    &:hover{
                        text-decoration: none;
                        color: $monza-normal;
                    }
                    &:active{
                        color: $monza-dark;
                    }
                }
                .help{
                    margin-left: .5rem;
                    display: inline-block;
                }
                .return-wrapper{
                    display: flex;
                    align-items: center;
                    background: $chatams-blue;
                    color: $white;
                    transition: all .2s ease;
                    &:hover{
                        text-decoration: none;
                        background: $monza-normal;
                    }
                    &:active{
                        background: $monza-dark;
                    }
                    
                    @media screen and (min-width: $media-small-land) {
                        margin-right: 1rem;
                    }
                    .material-icons{
                        padding-left: 0.5rem;
                    }
                    .return-link{
                        color: $white;
                        padding: 0.5rem;
                    }
                    
                }
            }
        }
        .request-content{
            .request-success{
                height: calc(100vh - 590px);
            }
            .heading-page{
                text-align: center;
                margin-bottom: 2rem;
                font-size: 27px;
                font-weight: bold;
            }
            .info-text-page{
                //line-height: 20px;
                color: $mine-shaft;
            }

            .request-form{

                .field-container{
                    margin-top: 3rem;
                    .legend-heading{
                        font-size: 18px;
                        padding-bottom: 2rem;
                        width: 100%;
                        color: $mine-shaft;
                        &::after{
                            content: '';
                            width: calc(100% - 30px);
                            height: 2px;
                            position: absolute;
                            bottom: 20px;
                            left: 15px;
                            background-color: $mine-shaft;
                        }
                    }
                    .datepicker-group {
                        margin-top: 1rem;
                        height: 60px;
                    }
                    .radio-group{
                        @include flexbox(100%, row, flex-start, center);
                        height: 60px;
                        &__label{
                            margin-right: 1rem;
                            color: $mine-shaft;
                            //margin-bottom: 1rem
                        }
                        &__inputs{
                            @include flexbox(auto, row, flex-start, center);
                        }
                    }
                    .upload-wrapper{
                        .button-upload{
                            margin-top: 1rem;
                        }
                    }
                    .combo-wrapper {
                        width: auto;
                        font-family: $font;
                        margin-bottom: 1em;
                        label {
                            margin: 0 6px 5px 0;
                            font-size: 16px;
                        }
                    }
                    .input-group{

                        &__label{
                            margin-bottom: 1rem;
                        }
                    }
                    .column-wrapper{
                        width: 100%;
                        @media screen and (min-width: $media-medium) {
                            width: 50%;                            
                        }
                        .no-label{
                            label{
                                display: none;
                            }
                        }
                    }
                    .heading-form{
                        font-size: 18px;
                        padding: 1rem;
                        width: 100%;
                    }
                    .heading-form__group{
                        margin-bottom: 1.5rem;
                        font-size: 14px;
                        color: $mine-shaft;
                    }
                    &.questions-radios{
                        .radio-group{
                            justify-content: space-between;
                            margin-bottom: 1rem;
                            height: auto;
                            @media screen and (min-width: $media-medium) {
                                margin-bottom: 0;     
                                height: 60px;                      
                            }
                            .radio-group__inputs{
                                width: 105px;
                            }
                            .radio-group__label{
                                width: calc(100% - 125px);
                                line-height: 20px;
                            }
                        }
                    }
                    .upload-container{
                        &__list{
                            padding-left: 15px;
                            &-item{
                                list-style-type: none;
                                margin-bottom: 2rem;
                                .upload-wrapper{
                                    .button-upload {
                                        width: auto;
                                        display: flex;
                                        align-items: flex-end;
                                        .file-name{
                                            margin-right: .5rem;
                                            font-size: 14px;
                                            color: $chatams-blue;
                                            display: inline-block;
                                            margin-bottom: 2px;
                                            max-width: 200px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                        .file-wrapper {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            a{
                                                color: $white;
                                                font-weight: normal;
                                                text-decoration: none; 
                                                width: 36px;
                                                height: 36px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                margin-left: 0.5rem;
                                                background: $chatams-blue;
                                                cursor: pointer;
                                                &:hover {
                                                    background-color: $monza-normal; 
                                                }
                                                &:active {
                                                    background-color: $monza-dark; 
                                                }
                                            }
                                        }
                                        .inputfile{
                                            width: 0.1px;
                                            height: 0.1px;
                                            opacity: 0;
                                            overflow: hidden;
                                            position: absolute;
                                            z-index: -1;
                                        }
                                        label {
                                             @include flexbox(auto, row, flex-start, center);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .info-fields{
                    color: $monza-normal;
                    font-size: 14px;
                    margin-left: 1rem;
                    font-style: italic;
                }
                .info-combo-option{
                    font-style: italic;
                    font-size: 14px;
                    margin-bottom: .5rem;
                }
                .form-wrapper{
                    width: 100%;
                    .force-label{
                        margin: 0 6px 5px 0;
                        padding-left: 0;
                        color: var(--color-secondary-variant);
                        font-weight: 600;
                        font-size: 12px;
                        display: inline-block;
                    }    
                    
                    &.last-exercices-input {
                        .input-container {
                            padding-bottom: 2em;
                        }
                    }
                }
                .checks-wrapper{
                    &__item{
                        margin-bottom: 1rem;
                    }
                }
                .bottom-text{
                    //line-height: 20px;
                    font-size: 14px;
                }
                .btn-submit-container{
                    margin-right: 0;
                    margin-left: 0;
                    .btn-submit-wrapper{
                        @include flexbox(100%, row, flex-end, center);
                    }
                }
            }
        }
    }
    //hide footer only in request page
    & + .footer{
        display: none !important;
    }
}