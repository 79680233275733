@import "@backoffice/scss/index.scss";
.userdetail {
    padding: 24px;
    width: 100%;
    .return {
        margin-bottom: 1rem;
        .go-back {
            @include flexbox(100%, row, flex-start, center);
            color: $color-text;
            font-weight: bold;
            transition: 0.2s ease all;
            cursor: pointer;
            &:hover {
                text-decoration: none;
                color: $color-secondary;
            }
        }
    }
    .title {
        @include title(24px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 2rem;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-primary;
    }

    .userdetail-form {
        .row {
            flex-wrap: wrap;
        }
        .btn-end {
            @include flexbox(100%, row, flex-end, center);
            button{
                width: 100%;
            }
        }
    }
}
