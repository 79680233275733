@import "../../scss/index.scss";
.layout {
    height: 100vh;
    background-color: $white;
    margin-bottom: 6rem;

    @media screen and (min-width: 900px) {
        margin-bottom: 0;
    }

    .MuiToolbar-gutters {
        padding: 0;
    }
    .main {
        @include flexbox(100%, row, flex-start, flex-start);
        
        @media screen and (min-width: $media-medium) {
            height: 100%;
        }
        .main-content {
            @include flexbox(100%, column, flex-start, center);
            padding: 2rem;
            @media screen and (min-width: $media-medium) {
                width: calc(100% - 250px);
                margin-left: 250px;
            }
        }
        .main-content-full{
            @include flexbox(100%, column, flex-start, flex-start);
        }
    }
}
