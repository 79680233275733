@import '../../../../scss/index.scss';

.selfAssessment-edit-modal{

    .message{
        margin-bottom: 3rem;
        text-align: center;
    }

    .submessage{
        font-size: 14px;
        font-style: italic;
        margin-top: 1rem;
        color: $monza-normal;
        text-align: start;
    }
}