@import '../../../../../scss/index.scss';

.document-edit-modal{
    width: 100%;
    .form-document-edit{

        .form-input-group{
            width: 100%;
            .form-wrapper{
                width: 100%;
            }
        }

        .document-template {
            @include flexbox(100%, row, flex-start, center, wrap);
            gap: 10px;

            .button-upload {
                label {
                    width: fit-content;
                }

                .inputfile{
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                }
            }
        }

        .btn-submit-container{
    
            .btn-submit-wrapper{
                @include flexbox(100%, row, flex-end, center);
            }
        }
    }
} 