@import "bootstrap/scss/bootstrap";
@import "./scss/index.scss";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain) */

/*********RESET CSS NAVIGATOR***********/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-family: $font;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}
.bg-color-sand {
    background-color: $color-bg-sand !important;
}
html:focus-within {
    scroll-behavior: smooth;
}
iframe {
    z-index: 0 !important;
    visibility: hidden;
}
/*
*Buttons
*/
button {
    display: inline-block;
    border: none;
    border-radius: 0;
    padding: 1em 1.7em;
    margin: 0;
    text-decoration: none;
    background: transparent;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    @media screen and (min-width: $media-large) {
        padding: 1rem 2em;
    }
}

button:hover,
button:focus {
    outline: 0;
}
//Explicitly remove focus outline in Chromium
button:focus:not(:focus-visible) {
    outline: 0;
}

button:active {
    transform: scale(0.95);
}

input,
button,
select,
optgroup,
textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible; // Show the overflow in Edge
}
input[type="text"]:focus {
    outline: none;
}
[role="button"] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

/*Icons*/
.material-icons {
    font-family: "Material Icons" !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
}

/* GENERAL CUSTOM STYLES */

*,
html,
body {
    font-family: $font;
    font-size: $font-size + px;
    box-sizing: border-box;
    line-height: 120%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba($color-black, 0);
}
h1,
h2,
h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
    line-height: 1.2;
}
h1 {
    font-size: $font-size-base * 2;
}
h2 {
    font-size: $font-size-base * 1.5;
}
h3 {
    font-size: $font-size-base * 1.125;
}
img {
    max-width: 100%;
}
/*
* Grid
*/

.camper-divider {
    width: 100%;
    height: 1px;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #aed1a9;
}
.float-end {
    float: right !important;
}
.stretch-content {
    display: flex;
    align-self: stretch;
    flex: 1;
}

.fixed-label-wrapper {
    margin-bottom: 1rem;
}

.fixed-label-response {
    @include flexbox(100%, column, center, center);
    padding: 5px;
    font: $font;
    font-weight: bold;
    color: $color-blue-dark;
    height: 38px;
    &.success {
        background: $color-light-green-clear;
    }
    &.error {
        background: $color-red;
    }
    &.warning {
        background: $color-bg-sand;
    }
}

.mc-button-center {
    text-align: center;
}

.mc-button-fr {
    float: right;
}

.mc-button-fl {
    float: left;
}

.mc-button-lg {
    min-width: 300px;
}

.mc-button-md {
    min-width: 250px;
}
*::placeholder{
    font-size: 13px;
    color: $mine-shaft;
}
input:disabled::-webkit-input-placeholder { /* WebKit browsers */
    font-size: 13px;
    color: $silver !important;
    font-family: $font !important;
}
input:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: 13px;
    color: $silver !important;
    font-family: $font !important;
}
input:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-size: 13px;
    color: $silver !important;
    font-family: $font !important;
}
input:disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
    font-size: 13px;
    color: $silver !important;
    font-family: $font !important;
}

.required {
    color: $color-red;
    font-size: 0.8em;
    line-height: 0;
    vertical-align: baseline;
    top: -0.5em;
}
/*select*/
.e-btn,
.e-css.e-btn {
    -webkit-tap-highlight-color: transparent;
    background-color: #ffffff;
}
.e-btn:hover,
.e-css.e-btn:hover {
    -webkit-tap-highlight-color: transparent;
    background-color: $color-white-clear;
}

.e-richtexteditor.e-rte-tb-expand {
    border: 1px solid $color-disabled !important;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
    .e-rte-content {
        border-bottom: 0;
    }
}
.e-control .e-richtexteditor .e-lib .e-rte-fixed-tb-expand .e-rte-toolbar-enabled .e-rte-tb-expand .e-focused {
    border: 1px solid $color-green-clear !important;
    padding: 5px;
    border-radius: 8px;
}

/*
* Generic Grid Style
*/
.e-gridpager.e-control.e-pager.e-lib {
    // border-right: 1.5px solid $color-primary;
    // border-left: 1.5px solid $color-primary;
    // border-bottom: 1.5px solid $color-primary;
    border-radius: 0px 0px 13px 13px;
    // grid-template-columns: 1fr 1fr;
    @media screen and (min-width: $media-large) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.e-pagercontainer {
    @media screen and (min-width: $media-large) {
        grid-column-start: 2;
        justify-self: center;
    }
}

.e-parentmsgbar {
    @media screen and (min-width: $media-large) {
        grid-column-start: 3;
        justify-self: flex-end;
    }
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
    background-color: $silver !important;
    border-color: $silver !important;
    color: #fff;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
    background-color: $athens-gray;
    border-color: $athens-gray;
    color: $athens-gray;
}

.e-grid.e-resize-lines .e-headercell .e-rhandler,
.e-grid.e-resize-lines .e-headercell .e-rsuppress {
    border-right: 0px;
}

.e-grid.e-gridhover
    tr[role="row"]:not(.e-editedrow)
    .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    color: $color-text;
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
    color: inherit;
}

.e-row .e-altrow {
    color: $color-text;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: $silver;
}

.e-grid .e-spinner-pane {
    display: none;
}

.e-grid .e-gridheader tr th:first-child {
    background-color: $silver !important;
}

.e-grid .e-gridheader tr th {
    //background-color: $color-primary !important;
}

.e-grid .e-detailindentcell {
    border: 0px !important;
}

.e-grid .e-detailcell {
    border: 0px;
}

.e-grid .e-content{
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $chatams-blue;
    }
}

.e-detailheadercell {
    background-color: $color-primary;
}

.e-columnheader {
    background-color: $color-primary;
}

.e-grouptopleftcell {
    background-color: $color-primary;
}

.e-gridheader tr th {
    background-color: $color-primary;
}

.e-groupcaption {
    background-color: $color-secondary !important;
}

.e-indentcell,
.e-recordplusexpand,
.e-recordpluscollapse {
    background-color: $color-secondary !important;
    .e-icons {
        padding-top: 15px !important;
    }
}

.e-grid .e-recordplusexpand {
    padding-top: 0;
}

.react-tagsinput-tag {
    background-color: $color-primary;
    color: white;
    border: 0px;
    border-radius: 12px;
    cursor: pointer;
    padding: 6px;
    font-weight: bolder;
}

.react-tagsinput--focused {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.react-tagsinput {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.react-tagsinput-input {
    width: 130px;
}

[data-tip] {
    position: relative;
}

[data-tip]:before {
    content: "";
    display: none;
    content: "";
    position: absolute;
    top: 30px;
    left: 35px;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
}
[data-tip]:after {
    display: none;
    content: attr(data-tip);
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 5px 8px;
    color: $color-text;
    background-color: white;
    z-index: 9;
    font-size: 0.75em;
    height: 18px;
    line-height: 18px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    word-wrap: normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
    display: block;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn .e-selected-color,
.e-colorpicker-container .e-split-btn-wrapper .e-split-colorpicker.e-split-btn .e-selected-color {
    height: 30px;
    width: 30px;
}

.e-split-preview {
    border: 1px solid;
}

.e-split-btn-wrapper {
    box-shadow: 2px 2px 4px 2px rgba(143, 143, 143, 0.5);
}

.e-header-popup {
    .e-header-calendar {
        .e-content {
            .e-cell.e-selected > span {
                background-color: $color-primary;
                border: none;
                box-shadow: none;
            }
        }
        .e-footer-container {
            button {
                color: $color-primary;
            }
        }

        .e-today {
            .e-day {
                border-color: $color-primary !important;
                color: $color-primary;
            }
            .e-selected {
                color: $color-primary;
            }
        }
    }
}
.e-popup-open{
    .e-content{
        .e-cell{
            &.e-today{
                span{
                    border: 1px solid $chatams-blue !important;
                    border-radius: 50%;
                    color: $chatams-blue !important;
                    &.e-day{
                        background-color: white !important;
                    }
                }
            }
            &.e-selected {
                span{
                    background-color: $chatams-blue !important;
                }
            }
        }
    }
}

.date-wrapper {
    width: 100%;
    //margin-top: 1rem;1
    & > * {
        border: none !important;
    }
    label {
        color: $color-blue-dark;
        font-size: 14px;
        font-weight: bold;
        margin: 0.5rem 0;
    }
    [class*='e-input-group']{
        background-color: $athens-gray;
        height: 37px;
        &::after, &::before{
            content: none;
        }
    }
    [class*='e-date-wrapper'] {
        border-radius: 0;
        border: 1px $mine-shaft solid;
        // [class*='e-datepicker'] {
        //     padding-left: 0 !important;
        // }
        [class*='e-clear-icon'] {
            position: absolute;
            top: 7px;
            right: 4px;
            color: $mine-shaft  !important;
        }
        [class*='e-input-group-icon'] {
            position: absolute;
            top: 3px;
            left: 4px;
            color: $color-green-clear  !important;
            display: none;
        }
        [class*='e-datepicker'],[class*='e-datetimepicker'] {
            padding-left: .5rem !important;
            font-size: 14px;
            font-family: $font;
        }
        // &:hover {
        //     border: 1px $color-green-clear solid !important;
        // }
    }
    [class*='e-datetime-wrapper']{
        [class*='e-datetimepicker'] {
            padding-left: .5rem !important;
            font-size: 14px;
            font-family: $font;
        }
    }
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after{background: transparent !important;}
.e-control.e-datepicker::placeholder{font-family: $font !important;}
.date-wrapper span{
    font-family: 'Urbanist' !important;
}

//e-date-range
.e-date-range-container{
    .e-range-header{
        display: none;
    }
}

//Edit Grid styles
.grid-wrapper{
    .e-headercontent{
        .e-columnheader{
            .e-headercell{
                padding: 0 10px 0;
                .e-headercelldiv{
                    padding: 0 1em 0 0.2em;
                }
            }
        }
    }
    .e-gridcontent{
        .e-row{
            .e-rowcell{
                padding: 8px 5px;
                color: $mine-shaft;
                font-size: 14px;
            }
        }
    }
}