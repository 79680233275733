@import "../../../scss/index.scss";

.textarea-wrapper {
	@include flexbox(100%, column, flex-start, flex-start);
	.textarea-label {
		color: $color-secondary-variant;
		// font-weight: bolder;
		margin: 0.5rem 0;
		display: inline-block;
		font-family: $font;
		font-size: 12px;
	}
	.textarea-control {
		width: 100%;
		border-color: transparent;
		border-radius: 0;
		padding: 0.5rem;
		resize: none;
		box-sizing: border-box;
		margin-bottom: 1rem;
		line-height: 1.3;
		background-color: $athens-gray;
		&:focus {
            border: 1px solid $silver;
            outline: none !important;
        }
		font-size: 14px;
		&.textarea-error {
			background-color: $pot-pourri;
            color: $monza-normal;
            margin-bottom: 0;
		}
	}

	.error {
        color: $monza-normal;
        font-size: 12px;
    }

	//disabled
	&.isDisabled {
		opacity: 1;
		.textarea-control {
			//background-color: rgb(242, 242, 242);
			color: #989393;
		}
	}
}
