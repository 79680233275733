@import "../../../scss/index.scss";
@import "../../../../src/components/FormComponents/Input/Input.scss";

.inputAutoSuggest {
    @include flexbox(auto, column, flex-start, flex-start);
    position: relative;
    margin-bottom: 0.7rem;

    .input-container {
        width: 100%;
    }

    .suggestedResults {
        position: absolute;
        border-radius: 5px;
        background: $color-white;
        color: $color-blue-dark;
        width: auto;
        min-width: 275px;
        height: auto;
        top: 57px;
        left: 0;
        z-index: 9999;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }
    
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #F5F5F5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $silver;
        }

        &.backoffice-search {
            left: 0;
            top: 64px;
            border-radius: 0;
            min-width: 302px;
            max-height: 228px;
            overflow-y: auto;
            width: 100%;
            border: 1px solid $chatams-blue;

            &.no-label {
                top: 40px;
            }
        }
        .results-list {
            &__item {
                padding-bottom: 8px;
                padding-top: 8px;
                color: $color-text;
                font-size: 14px;
                line-height: 20px;
                @include flexbox(100%, column, flex-start, flex-start);
                background-color: $athens-gray;
                &:hover {
                    cursor: pointer;
                    background-color: $color-blue-clear;
                    color: $color-white;
                }
                small {
                    padding: 0 0.5rem;
                }
            }            
        }
    }
}
