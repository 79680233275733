@import '../../../scss/index.scss';

.improvement-plans-header {
    width: 100%;

    .title {
        font-family: "Urbanist", sans-serif;
        font-size: 24px;
        font-weight: bolder;
        color: $color-text;
        text-align: left;
        letter-spacing: 0;
        line-height: normal;
        margin-bottom: 20px;
        padding-bottom: 10px;
        padding-top: 0.5rem;
        border-bottom: 1px solid $color-primary;
    }
}
.improvement-plans {
    @include flexbox(100%, column);

    &__row {
        @include flexbox(100%, row, space-between, center, wrap);

        &__inputs-wrapper {
            @include flexbox(100%, column, space-between, flex-start, nowrap);
            gap: 20px;
            margin-bottom: 20px;

            @media screen and (min-width: $media-small-land) {
                flex-direction: row;
                margin-bottom: 10px;
            }

            &__item {
                width: 100%;
            }

            .input-container {
                width: 100%;
                .inputLabel {
                    font-weight: 400;
                }
            }

            .date-wrapper {
                .e-date-wrapper {
                    margin: 0;
                }
            }

            .combo-wrapper {
                width: 100%;
            }
        }

        &__buttons-wrapper {
            @include flexbox(100%, row, space-between, center, nowrap);
            gap: 20px;
        }

        &__table-wrapper {
            width: 100%;

            .grid-wrapper-goals {
                .table-header, .table-body__row {
                    grid-template-columns: repeat(3, 1fr) 70px;
                }
            }
            .grid-wrapper-actions {
                .table-header, .table-body__row {
                    grid-template-columns: repeat(4, 1fr) 70px;
                }
            }
            .grid-wrapper-goals, .grid-wrapper-actions {
                .table-header, .table-body__row {
                    display: grid;
                }

                .table-header {
                    &__th {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: $mine-shaft;
                        font-weight: bold;
                        font-family: $font;
                        font-size: 14px;
                        border-bottom: 1px solid $gallery;
                        height: 42px;
                    }
                }

                .table-body {
                    &__row {
                        &__td {
                            padding: 8px 5px;
                            color: $mine-shaft;
                            font-size: 14px;
                            border-bottom: 1px solid #e0e0e0;
                        }
                    }
                }
            }
        }

        &.goals_wrapper, &.actions-wrapper {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid;
        }

        &__buttons-wrapper {
            margin-bottom: 20px;
        }

        &.buttons-wrapper {
            @include flexbox(100%, row, flex-end, center);
            margin: 50px 0px;
            gap: 1rem;
        }

        &__error-wrapper {
            width: 100%;
            color: $monza-normal;
            font-size: 12px;
            margin-bottom: 1rem;
        }
    }
}