@import '../../scss/index.scss';

.title-header-page {
    @include title(22px, $mine-shaft, left, 1px, normal, bolder);
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $mine-shaft;
}
.user-container{
    top: 0;
    right: 0;
    padding-right: 5px;
    position: absolute;
    z-index: 50;
    display: flex;
    align-items: center;

    @media screen and (min-width: $media-medium) {        
        right: 40px;
        top: 20px;
        padding-right: 0;
    }


    .help-wrapper{
        margin-right: 1rem;
        .return-wrapper{
            display: flex;
            align-items: center;
            background: #104177;
            color: #ffffff;
            transition: all 0.2s ease;
            padding: 0.35rem;
            &:hover{
                text-decoration: none;
                background: $monza-normal;
            }
            &:active{
                background: $monza-dark;
            }
        }
    }
    .user-wrapper{

        .user-text{
            color: $white;
            @media screen and (min-width: $media-medium) {
                color: $chatams-blue;
            }
        }
    }
}