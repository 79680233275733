@import '../../../../../scss/index.scss';

.contract-upload-modal {    
    .text-danger-contract {
        color: $monza-normal;
        font-weight: bold;
        font-style: italic;
        margin-top: 10px;
    }
    .inputfile{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        &:disabled{
            & + label{
                background: var(--color-primary-disabled);
                cursor: not-allowed;
                border: transparent;
                color: #fff;
            }
        }
    }
    .btn-submit-container{
    
        .btn-submit-wrapper{
            @include flexbox(100%, row, flex-end, center);
            gap: 20px;
        }
    }
}