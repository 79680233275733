@import "@backoffice/scss/index.scss";

.questionnaires {
    width: 100%;
    margin-top: 2rem;

    .add-questionnaire-wrapper {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        // justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        align-content: normal;

        .combo-wrapper {
            margin-bottom: 0px;
        }
    }

    .request-grid {
        a {
            text-decoration: none;
        }

        .document-template {
            @include flexbox(100%, row, flex-start, center, wrap);
            gap: 10px;

            .button-upload {
                label {
                    width: fit-content;
                }

                .inputfile{
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                }
            }
        }
    }
}