@import '../../../../scss/index.scss';

.auditor-edit-modal{
    width: 100%;
    .form-auditor-edit{

        .form-input-group{
            width: 100%;
            .form-wrapper{
                width: 100%;                
            }
        }

        .btn-submit-container{
    
            .btn-submit-wrapper{
                @include flexbox(100%, row, flex-end, center);
            }
        }
    }
} 