@import "../../scss/index.scss";

.fixed-label_container {
    position: fixed;
    z-index: 1039;
    width: 85%;
    top: 2.5rem;
    left: 50%;
    transform: translate(-50%, 0);
    .fixed-label {
        @include flexbox(100%, row, flex-start, center);
        height: 38px;
        padding: 0 9px;
        box-sizing: border-box;
        span {
            font-size: 16px;
            color: $color-text; 
        }
        p {
            line-height: 16px;
            margin-left: 5px;
            color: $color-text;
            font-size: 12px;
        }
        &.success {
            background-color: $wild-willow !important;
        }
        &.error {
            background-color: $pot-pourri !important;
        } 
        &.warning {
            background-color: $energy-yellow !important;
        }
    }
}
