@import "../../../../scss/index.scss";

.questionnaire-detail{
    @include flexbox(98%, column, flex-start, flex-start);
    margin: auto;
    color: $mine-shaft;
    .top-progress-bar{
        width: 65%;
        height: 3px;
        background: red;
        position: absolute;
        top: 107px;
        z-index: 99;

        &.fixedbar{
            position: fixed;
            top: 0;
        }
        &.fixedbar0{
            top: 130px;
        }
    }
    &-form{
        width: 100%;
        //overflow: hidden;
    }
    &__heading{
        @include flexbox(100%, column, flex-start, flex-start);
        border-bottom: 1px solid;
        background: white;
        z-index: 10;
        position: relative;
        padding-bottom: 0.5rem;
        max-width: calc(1440px - 0.8rem);
        margin: 0 auto;
        padding-top: 1rem;
        //margin-bottom: 2rem;
        .heading-logo-container{
            .logo-wrapper{
                width: 250px;
                margin: 1rem auto;  
                @media screen and (min-width: $media-medium) {
                    width: 250px;
                    margin: inherit;
                }
                .logo-img{
                    width: 100%;
                    height: auto;
                    margin-left: -23px; //i'm not proud about this...
                }
            }
            .return-wrapper{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0 0 1rem 0;
                background: $chatams-blue;
                width: 90px;
                color: $white;
                padding: 0.5rem;
                cursor: pointer;
            }
        }

        .logo-container{

            .logo-wrapper{
                width: 250px;
                margin: 1rem auto;
                @media screen and (min-width: $media-medium) {
                    width: auto;
                    margin: inherit;
                }
            }
        }

        .user-container{
            position: absolute;
            right: 0;
            top: 20px;
            z-index: 99999;
            display: flex;
            align-items: center;
            .help-wrapper{
                margin-right: 1rem;
                .return-wrapper{
                    display: flex;
                    align-items: center;
                    background: #104177;
                    color: #ffffff;
                    transition: all 0.2s ease;
                    padding: 0.35rem;
                    &:hover{
                        text-decoration: none;
                        background: $monza-normal;
                    }
                    &:active{
                        background: $monza-dark;
                    }
                }
            }
            .user-wrapper{
    
                .user-text{
                    color: $chatams-blue;
                }
            }
        }
    }
    .flex-container{
        @include flexbox(100%, row, flex-start, flex-start);
        @include container();
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        @media screen and (min-width: $media-medium) {
            @include container();
            padding-top: 0;
        }
    }
    &-aside{
        width: 200px;
        padding: 1rem 1rem 1rem 0;
        position: fixed;
        top: 5px;
        height: calc(100vh - 188px);
        overflow-y: scroll;
        transition: .2s ease all;
        scroll-behavior: smooth;
        @media screen and (min-width: $media-medium) {
            width: 330px;
        }
        .aside-content{
            margin-top: .5rem;
            .menu-wrapper{
                border-left: 1px solid $silver;
                .menu-list{ //<ul>
                    padding-left: 2rem;
                    &__item{ //<li>
                        position: relative;
                        &.active{
                            &::before{
                                content: "";
                                width: 7px;
                                height: 30px;
                                background: red;
                                position: absolute;
                                left: -36px;
                                top: 7px;
                            }
                        }
                        .item-wrapper{
                            
                            &__title{
                                @include title(24px, $color-text, left, 0, normal, bolder);
                                margin-bottom: 1rem;
                                padding-top: .5rem;
                                display: inline-block;
                                &:hover{
                                    text-decoration: none;
                                    color: $monza-normal;
                                }
                            }
                            &__content {
                                @include flexbox(100%, row, flex-start, center);
                                margin-bottom: 1rem;
                                .sub-title {
                                    margin-right: .5rem;
                                    color: $silver;
                                    &:hover{
                                        text-decoration: none;
                                        
                                    }
                                }
                                .points-container {
                                    @include flexbox(auto, row, flex-start, center);
                                   
                                    .answer-rate {
                                        margin-left: .5rem;
                                        border: 1px solid $monza-normal;
                                        color: $monza-normal;
                                        padding: 1px 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 2px white;
            background-color: white;
        }
    
        &::-webkit-scrollbar {
            width: 2px;
            background-color: white;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: white;
        }
    }
    &-content{
        width: calc(100% - 330px);
        padding: 1rem;
        margin-bottom: 4rem; //TODO Change to 2rems
        margin-left: 200px;
        //title
        @media screen and (min-width: $media-medium) {
            margin-left: 330px;
        }
        .row-container{
            width: 100%;
            margin-top: 0;
            .selfassessment-wrapper{
                .anchor-container{    padding-bottom: 50px;}
            }
            .title-wrapper{
                .title {
                    @include title(24px, $color-text, left, 0, normal, bolder);
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    padding-top: .5rem;
                    border-bottom: 1px solid $color-primary;
                }
            }
            .description-wrapper{
                
                p{
                    margin-bottom: 1rem;
                    line-height: 22px;
                }
            }
            .questions-wrapper {
                
                .block-item{

                    .title-wrapper{

                        .title-3{
                            @include title(26px, $color-text, center, 0, normal, bolder);
                            margin-bottom: 1rem;
                            //padding-top: .5rem;
                        }
                    }
                    .area-title-wrapper{
                        .title-4 {
                            @include title(24px, $silver, left, 0, normal, bolder);
                            margin-bottom: .5rem;
                            padding-bottom: .5rem;
                            border-bottom: 1px solid $mine-shaft;
                            .company-points{
                                display: inline-block;
                                font-size: 18px;
                                color: black;
                                margin-left: 1rem;
                                font-weight: normal;
                            }
                        }
                    }
                    .title-5{
                        //@include title(20px, $silver, left, 0, normal, bolder);
                        font-size: 20px;
                        margin-bottom: 1rem;
                    }
                    .content-wrapper{
                        
                        .content-title{
                            //h5s
                            @include title(18px, $chatams-blue, left, 0, normal, bolder);
                            display: flex;
                            max-width: 90%;
                            align-items: flex-start;
                            width: 100%;
                            .title-6{
                                width: calc(100% - 30px);
                                color: black;
                                span{
                                    font-style: italic !important;
                                }
                            }
                            .auditor-icon{
                                width: 30px;
                                display: inline-block;
                                cursor: pointer;
                            }
                        }
                        .questions-wrapper{
                            margin-left: 1rem;
                            .questions{
                                //margin-bottom: 3rem;
                                &.area-question{
                                    .form-input-group{
                                        flex-wrap: nowrap;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        .textarea-wrapper {
                                            .textarea-control {
                                                height: 200px;
                                            }
                                        }
                                        .radio-container{
                                            width: 15%;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-end;
                                            margin-bottom: 0;
                                            height: 195px;
                                        }
                                    }
                                }
                            }
                            .questions-heading{
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
                                margin-bottom: 0.5rem;
                                padding-bottom: 0.5rem;
                                // position: absolute;
                                // right: 0;
                                .question-text{
                                    @include title(18px, inherit, left, 0, normal, bold);
                                }
                                .auditor-title{
                                    margin-right: 2rem;
                                }
                            }
                            .radio-container {
                                @include flexbox(100%, row, space-between, flex-start, nowrap);
                                margin-bottom: 1.5rem;
                                .company-column{
                                    @include flexbox(100%, row, flex-start, flex-start);
                                }
                                .auditor-column{
                                    //@include flexbox(auto, row, flex-start, center);
                                    position: relative;
                                    .points{
                                        margin-left: 2.4rem;
                                        margin-top: -14px;
                                    }
                                    //Special checks for auditor        
                                    [type="radio"]:checked + label:before{
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: -14px;
                                        width: 22px;
                                        height: 22px;
                                        border: 2px solid $chatams-blue;
                                        border-radius: 100%;
                                        background: #fff;
                                    }
                                    [type="radio"]:not(:checked) + label:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: -14px;
                                        width: 22px;
                                        height: 22px;
                                        //border: 2px solid $chatams-blue;
                                        border-radius: 100%;
                                        background: $athens-gray;
                                    }
                                    [type="radio"]:checked + label:after,
                                    [type="radio"]:not(:checked) + label:after {
                                        content: '';
                                        width: 10px;
                                        height: 10px;
                                        background: $chatams-blue;
                                        position: absolute;
                                        top: -8px;
                                        left: 6px;
                                        border-radius: 100%;
                                        -webkit-transition: all 0.2s ease;
                                        transition: all 0.2s ease;
                                    }  
                                    [type=radio]:checked:disabled + label:before {
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        top: -14px;
                                        width: 22px;
                                        height: 22px;
                                        border: 2px solid #c9ccd1;
                                        border-radius: 100%;
                                        background: #fff;
                                    }
                                    [type=radio]:checked:disabled + label:after, [type=radio]:not(:checked):disabled + label:after {
                                        content: "";
                                        width: 10px;
                                        height: 10px;
                                        background: #c9ccd1;
                                        position: absolute;
                                        top: -8px;
                                        left: 6px;
                                        border-radius: 100%;
                                        transition: all 0.2s ease;
                                    }  
                                }
                                .points{
                                    width: 30px;
                                    min-height: 16px;
                                    margin-top: 3px;
                                    &.hide{
                                        visibility: hidden;
                                    }
                                }
                                .radio-wrapper {
                                    width: calc(100% - 50px);
                                    label{
                                        padding-left: 40px;
                                        max-width: 880px;
                                    }

                                }
                            }
                            .form-wrapper{
                                width: 88%;
                                .force-label{
                                    margin: 0 6px 5px 0;
                                    padding-left: 0;
                                    color: var(--color-secondary-variant);
                                    font-weight: 600;
                                    font-size: 12px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
        .btn-wrapper{
            position: sticky;
            bottom: 3.73rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            
            .actions-wrapper{    
                display: flex;
                align-items: center;
                justify-content: flex-start;
                button:first-of-type{
                    background-color: $white;
                }
                button:last-of-type{
                    margin-left: 1rem;
                }
            }
        }
    }
}

//Modal
#selfAssesmentDescriptionModal{

    .form-input-group {
        flex-direction: column;
        .btn-wrapper{
            .save-wrapper{
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}