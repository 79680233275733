@import '../../../../scss/index.scss';

.additional-info {

    .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2rem;
    }

    .gridTable {
        @include gridTable();

        .action-template {
            justify-content: center;
            align-items: center;
            
            .file-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                .file-link{
                    color: $chatams-blue;
                    font-weight: normal;
                    text-decoration: none; 
                    min-width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    &:hover {
                        color: $monza-normal;
                    }
                    &:active {
                        color: $monza-normal;
                    }
                }
            }
        }

    }

    .fields-search-wrapper {
        width: 250px;
        display: inline-block;
        margin-right: 1rem;
    }

    .add-documents-wrapper, .add-notes-wrapper {
        @include flexbox(100%, row, flex-end, flex-start);
    }
}