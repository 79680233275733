@import "@backoffice/scss/index.scss";

.resetPasswordForm{
	@include flexbox(auto, column, center, center, nowrap);
    height: 100vh;
    box-sizing: border-box;
    padding: 10px;
    
    @media screen and (min-width: $media-x-small) {
        padding: 20px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (min-width: $media-medium) {
        flex-direction: row;
    }
	.login-wrapper{
        &:last-of-type{
            border-top: 1px solid $mine-shaft;
            @media screen and (min-width: $media-medium) {
                border: none;
            }
        }
        .img-wrapper {
            width: auto;
            font-size: inherit;
            @media screen and (min-width: $media-medium) {
                width: 375px;
            }
            .logo {
                width: 100%;
                height: auto;
            }
        }
		.resetpassword-form{
            padding: 2rem 0;
            @media screen and (min-width: $media-x-small) {
                width: 375px;
            }
			@media screen and (min-width: $media-medium) {
				padding: 2rem;
			}
			.resetpassword-title {
				font-size: 14px;
				margin-bottom: .5rem;
			}
			.link-forgotPass {
				@include flexbox(auto, row, flex-end, center);
				font-size: 14px;
				margin-bottom: 2rem;
				cursor: pointer; 
				span{
					&:hover {
						color: $wild-willow;
					}
				}
			}
			.button-container{
				@include flexbox(auto, row, center, center);
			}
		
			.pointer {
				cursor: pointer;
			}
		}
        .not-equals-message {
            font-size: 12px;
            margin-bottom: .5rem;
            color: $monza-normal;
        }
        .info-password{
            font-size: 12px;
            margin-bottom: 1.5rem;
        }
    }

	.separator{
        border-left: 1px solid $mine-shaft;
        height: 375px;
        display: none;
        @media screen and (min-width: $media-medium) {
            display: block;
        }
    }
}