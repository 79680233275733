@import "../../../scss/index.scss";

.combo-wrapper {
    width: auto;
    font-family: $font;
    margin-bottom: 1em;
    label {
        margin: 3px 6px 5px 0;
        // padding-left: 0;
        // color: $color-secondary-variant;
        // font-weight: 600;
         font-size: 12px;
        // display: inline-block;

        &.no-label-combo {
            display: none;
        }
        .combo-required {
            color: $color-red;
        }

        & + div {
            //Combobox
            &.comboBox {
                width: auto;
                [class*="-control"] {
                    min-height: unset;
                    //height: 32px;
                    min-height: 37px;
                    font-size: 14px;
                    height: auto;
                    border-radius: 0;
                    background-color: $athens-gray;
                    border: none;
                    box-shadow: none;
                    cursor: pointer;
                    font-family: $font;

                    
                    & > [class*="-ValueContainer"] {
                        padding: 0px 10px;

                        & > [class*="-placeholder"]{
                            color: $color-gray-clear;
                            font-size: 13px;
                        }
                    }

                    & > [class*="css-"] {
                        &:last-of-type {
                            height: 32px;
                            width: 33px;
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            [class*="-indicatorSeparator"] {
                                display: none;
                            }
                            [class*="-indicatorContainer"] {
                                padding: 0;
                                left: 4px;
                                top: 10px;
                                position: absolute;
                                color: $color-primary;
                                &:nth-child(1) {
                                    display: none;
                                    left: -12px;
                                }
                            }
                        }
                    }

                    //pills
                    [class*="-singleValue"] {
                        font-size: 14px;
                        max-width: calc(100% - 28px);
                    }
                    [class*="-multiValue"] {
                        background-color: $chatams-blue !important;
                        border-radius: 0;
                        color: white;
                        border: 1px solid $color-secondary-variant;
                        & > div:first-of-type {
                            color: white;
                            font-weight: bold;
                        }
                        & > div:last-of-type {
                            &:hover {
                                color: $color-primary-variant;
                                background-color: transparent;
                            }
                        }
                    }
                }
                [class*="-menu"] {
                    border-radius: 0;
                    margin-top: 0;
                    box-shadow: none !important;
                    
                    [class*="-MenuList"], & > div:first-of-type {
                        overflow-x: hidden;
                        padding: 0;
                        white-space: nowrap;
                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            background-color: #F5F5F5;
                        }
                    
                        &::-webkit-scrollbar {
                            width: 6px;
                            background-color: #F5F5F5;
                        }
                    
                        &::-webkit-scrollbar-thumb {
                            background-color: $silver;
                        }
                        & [class$="-option"] {
                            //border-bottom: 1px solid $color-primary;
                            //background-color: $color-white;
                            background-color: $athens-gray;
                            height: 40px;
                            line-height: 25px;
                            color: $color-blue-dark;
                            font-size: 14px;
                        }
                        & [class$="-option"]:hover {
                            cursor: pointer;
                            background-color: $chatams-blue;
                            color: white;
                        }
                        & [class$="-option"]:focus {
                            cursor: pointer;
                            color: white;
                            background-color: $chatams-blue;
                        }
                    }
                }
            }

            input {
                font-size: 14px;
                font-family: $font;
            }

            i {
                color: $color-white;
                background: $color-secondary;
                padding: 7px;
                height: 16px;
                top: -1px;
                right: -1px;
            }
        }
    }
    // &.isFocus {
    //     .comboBox {
    //         [class*="-control"] {
    //             border-width: 2px;
    //             border-color: $color-primary;
    //         }
    //         & [class$="-menu"] {
    //             border-radius: 0;
    //             margin-top: 0;
    //             box-shadow: none !important;
    //             & [class$="-MenuList"], & > div:first-of-type {
    //                 overflow-x: hidden;
    //                 padding: 0;
    //                 white-space: nowrap;
    //                 & [class$="-option"] {
    //                     border-bottom: 1px solid $color-primary;
    //                     background-color: $color-white;
    //                     height: 40px;
    //                     line-height: 25px;
    //                     color: $color-text;
    //                     font-size: 14px;
    //                     &:first-of-type {
    //                         background-color: $color-white;
    //                     }
    //                     &:last-of-type {
    //                         background-color: $color-white;
    //                     }
    //                 }

    //                 & [class$="-option"]:hover {
    //                     cursor: pointer;
    //                     background-color: $chatams-blue;
    //                 }
    //                 & [class$="-option"]:focus {
    //                     cursor: pointer;
    //                     background-color: $chatams-blue;
    //                 }
    //             }
    //         }
    //     }
    // }

    &.isDisabled {
        [class^="ms-Label"] + div.comboBox [class*="-control"] {
            border-color: transparent;
            [class*="-singleValue"] {
                border-color: $color-gray-clear;
                background-color: $color-white-clear !important;
                color: $color-gray-clear;
            }

            & > [class*="css-"]:last-of-type [class*="-indicatorContainer"] {
                color: $color-gray-clear;
            }
        }
    }
}
