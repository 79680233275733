@import '../../../../scss/index.scss';

.user-edit-modal{
    width: 100%;
    .form-user-edit{

        .form-input-group{
            width: 100%;
            .form-wrapper{
                width: 100%; 
            }
            .btn-submit-container{
        
                .btn-submit-wrapper{
                    @include flexbox(100%, row, flex-end, center);
                }
            }
            .combo-wrapper {
                width: 100%;
            }
        }
    }
} 