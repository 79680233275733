@import "../../scss/index.scss";

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}
.fade {
    transition: opacity 0.15s linear;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}



.modal-footer {
    width: 100%;
}
.modal-content {
    border: none;
}
.backoffice-modal {
    border-radius: 0 !important;
    box-sizing: border-box;
    margin: 2em auto;

    .close {
        margin: 0;
        position: absolute;
        top: 10px;
        right: 20px;
        padding: 5px !important;
        color: $chatams-blue;
        opacity: 1;
        font-weight: normal;
        font-size: 3rem;
    }

    .modal-content {
        position: relative;
        @include flexbox(100%, column, flex-start, flex-start);
        border-radius: 0 !important;
        height: 100%;
        pointer-events: auto;
        background-color: $color-white;
        background-clip: padding-box;
        outline: 0;
        .modal-header {
            @include flexbox(100%, row, center, center, nowrap);
            border-bottom: none;
            border-radius: 0 !important;
            color: $chatams-blue;
            padding-left: 2em;
            font-size: 18px;
            margin-top: 2rem;
        
            &__add {
                background-color: $color-secondary;
            }
            &__blue {
                background-color: $color-blue-dark;
                color: white;
            }
            &__edit {
                background-color: $color-secondary;
            }
            &__warning {
                background-color: $color-orange-clear;
            }
            // &__danger {
            //     //background-color: $monza-normal;
            //     //color: $monza-dark;
            // }
            &__transparent {
                background-color: transparent;
                border-bottom: none !important;
            }
        
            .close {
                padding: 0;
                text-shadow: none;
                color: $chatams-blue;
            }
        
            h2,
            h3 {
                margin-bottom: 0;
                font-weight: 600;
            }
            .close {
                &:focus {
                    outline: none;
                }
                &:hover {
                    color: $toryblue;
                }
            }
        
            button {
                border: none;
                background: transparent;
                font-size: 2rem;
                span {
                    color: $color-blue-dark;
                    &:hover {
                        color: $color-blue-clear;
                    }
                }
            }
        }
        
        .modal-body{
            padding: 1rem 2rem 4rem 2rem !important;
            position: relative !important;
            width: 100% !important;
            color: $chatams-blue !important;
            .modal-text{
                line-height: 20px;
                font-size: 14px;
            }
        }
    }
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}
.modal-70w {
    width: 70%;
    max-width: none !important;
}
.modal-50w {
    width: 50%;
    max-width: none !important;
}
