@import '../../../../scss/index.scss';

.questionnaire-add-modal{
    width: 100%;
    .form-document-edit{

        .btn-submit-container{
    
            .btn-submit-wrapper{
                @include flexbox(100%, row, flex-end, center);

                .button-upload {
                    label {
                        width: fit-content;
                    }
    
                    .inputfile{
                        width: 0.1px;
                        height: 0.1px;
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: -1;
                    }
                }
            }
        }
    }
} 