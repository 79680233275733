@import '../../../scss/index.scss';

.request-detail {
    @include flexbox(98%, column, flex-start, flex-start);
    margin: auto;
    &__form{
        width: 100%;
    }
    &__heading{
        @include flexbox(100%, column, flex-start, flex-start);
        border-bottom: 1px solid;
        @include container();
        //margin-bottom: 2rem;
        position: relative;
        padding-top: 1rem;
        //padding-left: 0;
        .heading-logo-container{
            .logo-wrapper{
                width: 250px;
                margin: 1rem auto;
                @media screen and (min-width: $media-medium) {
                    width: 250px;
                    margin: inherit;
                }
                .logo-img{
                    width: 100%;
                    height: auto;
                    margin-left: -23px; //i'm not proud about this...
                }
            }
            .return-wrapper{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0 0 1rem 0;
                background: $chatams-blue;
                width: 90px;
                color: $white;
                padding: 0.5rem;
                cursor: pointer;
            }
        }

        .logo-container{

            .logo-wrapper{
                width: 250px;
                margin: 1rem auto;
                @media screen and (min-width: $media-medium) {
                    width: auto;
                    margin: inherit;
                }
            }
        }

        .user-container{
            position: absolute;
            right: 1.5rem;
            top: 20px;
            z-index: 999;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-between;
            height: 150px;

            .user-container-wrapper {
                display: flex;
                align-items: center;

                .help-wrapper{
                    margin-right: 1rem;
                    .return-wrapper{
                        display: flex;
                        align-items: center;
                        background: #104177;
                        color: #ffffff;
                        transition: all 0.2s ease;
                        padding: 0.35rem;
                        &:hover{
                            text-decoration: none;
                            background: $monza-normal;
                        }
                        &:active{
                            background: $monza-dark;
                        }
                    }
                }
                .user-wrapper{
        
                    .user-text{
                        color: $chatams-blue;
                    }
                }
            }
            .btn-deregister-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: flex-end;

                @media screen and (min-width: $media-small-land) {
                    flex-direction: row;
                }

                .badget_outline {
                    width: auto;
                    min-width: 130px;
                    height: 37px;
                    color: #fff;
                    font-weight: bold;
                    font-family: "Urbanist", sans-serif;
                    font-size: 14px;
                    background-color: #104177;
                    text-align: center;
                    padding: 0 25px;
                    border: 0px solid transparent;
                    outline: none;
                    display: inline-block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;

                    &-success {
                        color: $wild-willow;
                        border: 1px solid $wild-willow;
                    }
                
                    &-danger {
                        color: $monza-dark;
                        border: 1px solid $monza-dark;
                    }
                }
            }
        }
    }

    &__content {
        @include flexbox(100%, column, flex-start, flex-start);
        @include container();
        border-bottom: 1px solid;

        &:last-child {
            border-bottom: 0px;
        }
        .row{
            width: 100%;
        }
        .flex-container{
            @include flexbox(100%, row, flex-start, flex-start);

            .auditors-selected {
                @include flexbox(100%, row, space-between, flex-start, nowrap);
                gap: 10px;

                .combo-wrapper {
                    width: 100%;
                }
            }

            &.request-detail__content__header {                
                gap: 20px;

                @media screen and (min-width: 998px) {
                    flex-wrap: nowrap;
                }

                &__right {
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    gap: 20px;
                    
                    @media screen and (min-width: 998px) {
                        padding-top: 26px;
                        flex-wrap: nowrap;
                    }

                    .additional-buttons {
                        @include flexbox(100%, row, flex-end, center, nowrap);

                        .btn-icon-rotate {
                            span {
                                transition: all .2s ease;
                                transform: rotate(-180deg);
                            }
                        }
    
                        .btn-icon-unrotate {
                            span {
                                transition: all .2s ease;
                                transform: rotate(0deg);
                            }
                        }
                    }

                    .field-container{
                        width: 100%;
                        display: flex;  

                        .legend-heading{
                            font-size: 18px;
                            padding-bottom: 2rem;
                            width: 100%;
                            color: $mine-shaft;
                            &::after{
                                content: '';
                                width: calc(100% - 30px);
                                height: 2px;
                                position: absolute;
                                bottom: 20px;
                                left: 15px;
                                background-color: $mine-shaft;
                            }
                        }
                        .datepicker-group {
                            margin-top: 1rem;
                            height: 60px;
                        }
                        .radio-group{
                            @include flexbox(100%, row, flex-start, center);
                            height: 37px;
                            &__label{
                                font-size: 14px;
                                margin-right: 1rem;
                                color: $mine-shaft;
                                //margin-bottom: 1rem
                            }
                            &__inputs{
                                @include flexbox(auto, row, flex-start, center);
                            }
                        }
                        .upload-wrapper{
                            .button-upload{
                                margin-top: 1rem;
                            }
                        }
                        .combo-wrapper {
                            width: auto;
                            font-family: $font;
                            margin-bottom: 0;
                            label {
                                margin: 0 6px 5px 0;
                                font-size: 16px;
                            }
                        }
                        .input-group{
    
                            &__label{
                                margin-bottom: 1rem;
                            }
                        }
                        .column-wrapper{
                            width: 100%;
                            @media screen and (min-width: $media-medium) {
                                width: 50%;                            
                            }
                            .no-label{
                                label{
                                    display: none;
                                }
                            }
                        }
                        .heading-form{
                            font-size: 18px;
                            padding: 1rem;
                            width: 100%;
                        }
                        .heading-form__group{
                            margin-bottom: 1.5rem;
                            font-size: 14px;
                            color: $mine-shaft;
                        }
                        &.questions-radios{
                            .radio-group{
                                justify-content: space-between;
                                margin-bottom: 1rem;
                                height: auto;
                                @media screen and (min-width: $media-medium) {
                                    margin-bottom: 0;     
                                    height: 60px;                      
                                }
                                .radio-group__inputs{
                                    width: 105px;
                                }
                                .radio-group__label{
                                    width: calc(100% - 125px);
                                    line-height: 20px;
                                }
                            }
                        }
                        .upload-container{
                            &__list{
                                padding-left: 15px;
                                &-item{
                                    list-style-type: none;
                                    margin-bottom: 2rem;
                                    .upload-wrapper{
                                        .button-upload {
                                            width: auto;
                                            display: flex;
                                            align-items: flex-end;
                                            .file-name{
                                                margin-right: .5rem;
                                                font-size: 14px;
                                                color: $chatams-blue;
                                                display: inline-block;
                                                margin-bottom: 2px;
                                                max-width: 200px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                            .file-wrapper {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                a{
                                                    color: $white;
                                                    font-weight: normal;
                                                    text-decoration: none; 
                                                    width: 36px;
                                                    height: 36px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    margin-left: 0.5rem;
                                                    background: $chatams-blue;
                                                    cursor: pointer;
                                                    &:hover {
                                                        background-color: $monza-normal; 
                                                    }
                                                    &:active {
                                                        background-color: $monza-dark; 
                                                    }
                                                }
                                            }
                                            .inputfile{
                                                width: 0.1px;
                                                height: 0.1px;
                                                opacity: 0;
                                                overflow: hidden;
                                                position: absolute;
                                                z-index: -1;
                                            }
                                            label {
                                                 @include flexbox(auto, row, flex-start, center);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.space-between {
                justify-content: space-between;
            }
            .btn-wrapper.add{
                width: 100%;
                margin-bottom: 1rem;
                display: flex;
                justify-content: flex-end;
                @media screen and (min-width: 600px) {
                    margin-bottom: 0;
                    display: inherit;
                    width: auto;
                }
            }
        }
        .fields-wrapper{
            width: 250px;
            display: inline-block;
            margin-right: 0;
            @media screen and (min-width: 600px) {
                // margin-right: 1.5rem;
            }
            .input-container{
                width: 100%;
            }
            &:first-of-type{
                margin-bottom: 1rem;
                @media screen and (min-width: 600px) {
                    margin-bottom: 0;
                }
            }
            .ghost-input{
                height: 38px;
                background-color: #EDEFF2;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 0.5rem;
                font-size: 14px;
                color: #989393;
            }
        }
        .form-wrapper{
            width: 100%;
            @media screen and (min-width: 600px) {
                width: 250px;
            }
            &.large{
                
                width: 100%;
                @media screen and (min-width: $media-medium) {
                    width: 49%;
                }
            }

            &.middle{
                width: 100%;
                @media screen and (min-width: $media-medium) {
                    width: 24%;
                }
            }
        }
        
        .date{
            @include flexbox(100%, row, space-between, flex-start);
            @media screen and (min-width: 600px) {
                width: 100%;
            }
            .input-label{
                margin: 3px 6px 5px 0;
                font-size: 12px;
            }
            .row-wrapper{
                @include flexbox(100%, row, space-between, flex-start);
                .date-container{
                    @include flexbox(235px, row, space-between, center);

                    &:first-child {
                        margin-bottom: 10px;
                    }

                    .date-wrapper {
                        width: 180px;
                    }
                }
            }
            .e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled input.e-input, .e-input-group.e-control-wrapper.e-disabled input.e-input{
                background-color: $athens-gray;
                cursor: default;
            }
            &.readonly{
                //@include flexbox(inherit, row, center, center, nowrap);
                // justify-content: center;
                // align-items: center;
                // flex-wrap: nowrap;
                .e-icons {
                    display: none;
                }
            }
            
        }
        .evaluator-wrapper{
            width: 100%;
            @media screen and (min-width: 600px) {
                width: fit-content;
            }
            .input-container{
                width: 130px;
            }
        }
        .middle-wrapper{
            @include flexbox(100%, row, space-between, flex-start);
        }
        .evaluator-data{
            & + .middle-wrapper{
                width: 100%;
                @media screen and (min-width: 600px) {
                    @include flexbox(100%, row, space-between, flex-start);
                    
                }
            }
            .input-container{
                width: 100%;
            }
            &.full{
                width: 100%;
            }
            &.middle{
                width: 100%;
                @media screen and (min-width: 600px) {
                    width: 48%;
                    
                }
            }
        }

        //left side
        .left-wrapper{
            width: 100%;  
            padding:  0 1rem;
            box-sizing: border-box;
            border: 1px solid $gallery;
            @media screen and (min-width: $media-medium) {
                width: 49%;
            }
            .request-wrapper{
                @include flexbox(100%, row, space-between, center, nowrap);
                margin-bottom: 1rem;
                gap: 0.3rem;
                .title-wrapper{
                    margin-bottom: 0;
                }
                [class^="material"]{
                    &.eye{
                        color: $chatams-blue;
                        border: 1px solid;
                        padding: 9px;
                        cursor: pointer;
                        font-size: 16px;
                        transition: .2s ease all;
                        &:hover{
                            color: $monza-normal;
                        }
                    }
                }                
            }
        }
        .right-wrapper{
            width: 100%;  
            padding: 0 1rem;
            box-sizing: border-box;
            border: 1px solid $gallery;
            @media screen and (min-width: $media-medium) {
                width: 49%;
            }
        }
        .data-block {
            border-bottom: 1px solid;
            padding: 1rem 0;
            &:last-of-type{
                border-bottom: none;
                padding: 1rem 0;
            }
            .title-wrapper{
                @include flexbox(100%, row, flex-start, center);
                margin-bottom: 1rem;
                .title{
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 0;
                    margin-right: .5rem 
                }
                .state{
                    @include flexbox(auto, row, flex-start, center);
                    font-size: 14px;
                    .pill{
                        padding: 5px 10px;
                        border-radius: 20px;
                    }
                }
            }
            .content-wrapper{
                @include flexbox(100%, column, space-between, flex-start);
                margin-top: .5rem;
                margin-bottom: 0.5rem;
                @media screen and (min-width: 600px) {
                    flex-direction: row;
                    align-items: center;
                }
                .data{
                    font-size: 14px;
                    width: 100%;
                    margin-bottom: .5rem;
                    @media screen and (min-width: 600px) {
                        width: auto;
                        margin-bottom: 0;
                    }
                    .label{
                        margin-bottom: .5rem;
                    }
                    &.points-wrapper{
                        font-size: 12px;
                        color: $silver;
                        width: 100%;
                        .points-group{
                            @include flexbox(100%, row, space-between, center);
                            margin-bottom: .5rem;
                            
                        }
                        .percentage-bar{
                            height: 22px;
                            background-color: $athens-gray;
                            position: relative;
                            width: 100%;
                            margin-bottom: 1rem;
                            &__progress{
                                height: 100%;
                            }
                            &__data{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                color: $white;
                            }
                        }
                    }
                    .date-hide {
                        display: none;
                    }
                }
                .actions{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    .material-icons-round{
                        font-size: 16px;
                    }
                    &.flex-direction-column{
                        flex-direction: column;
                        gap: 20px;
                    }
                    // @media screen and (min-width: 600px) {
                    //     justify-content: flex-start;
                    //     width: auto;
                    // }
                    .upload-wrapper{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        &.column-reverse {
                            flex-direction: column-reverse;
                            gap: 30px;
                        }
                        .file-name{
                            font-size: 14px;
                            color: $chatams-blue;
                            display: inline-block;
                            margin-bottom: 2px;
                            max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: right;
                        }
                        .file-wrapper{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            a{
                                color: $white;
                                font-weight: normal;
                                text-decoration: none; 
                                width: 36px;
                                height: 36px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 0.5rem;
                                background: $chatams-blue;
                                cursor: pointer;
                            }
                            label {
                                margin-left: 10px;
                            }
                        }                        
                        .inputfile{
                            width: 0.1px;
                            height: 0.1px;
                            opacity: 0;
                            overflow: hidden;
                            position: absolute;
                            z-index: -1;
                            &:disabled{
                                & + label{
                                    background: var(--color-primary-disabled);
                                    cursor: not-allowed;
                                    border: transparent;
                                    color: #fff;
                                }
                            }
                        }
                        
                        .button-upload {
                            @include flexbox(auto, row-reverse, flex-start, flex-end);
                            &.link-contracts{
                            flex-direction: column;

                                &.g {
                                    width: 100%;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 20px;
                                    margin: 20px 0;

                                    @media screen and (min-width: $media-small) {
                                        flex-direction: row;
                                        width: 100%;
                                        justify-content: space-evenly;
                                    }

                                    @media screen and (min-width: $media-medium) {
                                        flex-direction: row;
                                        width: 100%;
                                        justify-content: space-evenly;
                                    }

                                    .button-down, .button-up {
                                        border: 1px solid $gallery;
                                        padding: 15px;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;

                                        .span-title {
                                            margin-bottom: 20px;
                                            font-size: 14px;
                                            font-weight: bold;
                                        }
                                    }
                                    
                                    .button-down {
                                        &__button {
                                            a {
                                                text-decoration: none;
                                                padding: 0;
                                                margin: auto;
                                                width: 140px;
                                            }
                                            span {           
                                                justify-content: center;                                 
                                                gap: 10px;
                                                padding: 0;
                                                margin: 0;
                                            }
                                        }
                                    }
                                    .button-up {
                                        &__button {
                                            label {
                                                justify-content: center;                                 
                                                gap: 10px;
                                                padding: 0;
                                                margin: 0;
                                                width: 140px;
                                            }
                                        }
                                    }
                                }
                            }
                            .file-wrapper {
                                .file-link {
                                    font-size: 16px;
                                }
                            }
                    
                            label {
                                @include flexbox(auto, row, flex-start, center);
                                margin-left: 0.5rem;
                            }
                        }
                    }
                    
                    &.btn-group{
                        width: 100%;
                        .btn-wrapper{
                            @include flexbox(100%, row, flex-end, center);
                            [class^="btn"]{
                                margin-right: 1rem;
                                &:last-of-type{
                                    margin-right: 0;
                                }
                            }
                        }
                        .btn-wrapper-improvement-plans {                  
                            @include flexbox(100%, column, flex-start, flex-end);          
                            gap: 20px;
                            
                            .btn-improvement-plan-wrapper {
                                @include flexbox(100%, row, flex-end, center);
                                gap: 20px;  

                                span {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .select-group{
                    width: 100%;
                    @media screen and (min-width: 600px) {
                        width: 50%;
                    }
                }
                .no-margin{
                    .checkbox{
                        margin: 0; 
                    }
                }
                .row-wrapper {
                    &.full {
                        width: 100%;
                        justify-content: space-between;

                        @media screen and (min-width: $media-medium) {
                            flex-wrap: nowrap;
                        }
                    }
                    .title-wrapper {
                        width: 100%;

                        @media screen and (min-width: $media-medium) {
                            width: 48%;
                        }
                    }
                    .combo-choose-evaluator {
                        flex-wrap: nowrap;
                        gap: 10px;
                        width: 100%;

                        @media screen and (min-width: $media-medium) {
                            width: 48%;
                        }

                        .combo-wrapper {
                            width: 80%;                            
                        }
                        .actions {
                            width: 37px;
                            height: 37px;
                            justify-content: center;
                            align-items: center;
                            background-color: $chatams-blue;
                            color: $color-white;
                            transition: all .3s ease;

                            &:hover {
                                background-color: $monza-normal;
                            }
                        }
                        &.full-combo{
                            .combo-wrapper {
                                width: 100%;
                            }
                        }
                    }
                }
                .actions-renewal-application {
                    margin-top: 20px;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .input-group{
                        width: 100%;

                        @media screen and (min-width: 600px) {
                            width: 48%;
                        }

                        .input-container {
                            width: 100%;
                        }
                    }
                }
            } 
        }
    }
    .last-wrapper{
        margin: 2rem 0;
        @include flexbox(100%, row, flex-end, center);
    }
    .separator{
        width: 98%;
        height: 2px;
        border-bottom: 1px solid;
        padding-top: 1rem;
    }    
}

