@import "../../../scss/index.scss";

.companies-info {
    width: 100%;

    &-filters {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        gap: 1rem;
        flex-wrap: wrap;
        
        .combo-wrapper,
        .inputAutoSuggest, 
        .input-wrapper,
        button {
            width: 100%;
            margin-bottom: 0;
        }

        button {            
            margin-bottom: 0.3rem;
        }

        @media screen and (min-width: $media-medium-land) {
            flex-direction: row;

            .combo-wrapper, 
            .inputAutoSuggest,
            .input-wrapper,
            button {
                width: 49%;
            }
        }
    }
}