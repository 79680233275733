@import '../../../../scss/index.scss';

.user-cv-edit-modal {
    .btn-submit-wrapper{
        .inputfile{
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
            &:disabled{
                & + label{
                    background: var(--color-primary-disabled);
                    cursor: not-allowed;
                    border: transparent;
                    color: #fff;
                }
            }
        }
        .file-wrapper{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            a{
                color: $white;
                font-weight: normal;
                text-decoration: none; 
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
                background: $chatams-blue;
                cursor: pointer;
            }
            label {
                margin-left: 10px;
            }
        } 
    }
}