@import "@backoffice/scss/index.scss";

.grid-wrapper {
	@include gridTableWrapper();
	.gridTable {
		@include gridTable();
	}
	margin-bottom: 4rem;
}

.e-bigger {
	margin-top: 20px;
	margin-bottom: 20px;
	.gridTable {
		@include gridTable();
		//border-radius: 10px;
	}
	.export-excel-wrapper {
		@include flexbox(100%, row, flex-start, center);
		margin-bottom: 25px;
		.export-excel-button {
			width: 100%;
		}
	}
	.e-gridcontent {
		border-radius: 10px;
		.e-content {
			//border-radius: 10px;
		}
	}
}
