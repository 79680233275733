@import "@backoffice/scss/index.scss";

.login-form {
    padding: 2rem 1rem;
    width: 100%;
    margin: auto;
    @media screen and (min-width: $media-x-small) {
        width: 375px;
    }
    @media screen and (min-width: $media-medium) {
        padding: 2rem;
    }

    .link-forgotPass {
        @include flexbox(auto, row, flex-end, center);
        font-size: 14px;
        margin-bottom: 2rem;
        span{
            border-bottom: 1px solid;
            &:hover {
                color: $monza-normal;
            }
        }
    }
    .input-control + .material-icons {
        color: $chatams-blue;
    }
    .button-container{
        @include flexbox(auto, row, center, center);
    }

    .pointer {
        cursor: pointer;
    }
}
