@mixin flexbox($width: 100%, $direction: row, $j: space-between, $a: center, $wr: wrap, $ac: normal) {
    @include displayFlex();

    width: $width;
    flex-direction: $direction;
    -webkit-justify-content: $j;
    justify-content: $j;
    align-items: $a;
    flex-wrap: $wr;
    align-content: $ac;
}

@mixin displayFlex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin grid($columns, $gap) {
    display: grid;
    grid-template-columns: $columns;
    grid-gap: $gap;
}

@mixin container {
    width: 100%;
    height: auto;
    max-width: calc(1440px - 0.8rem);
    padding-top: 2.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.8rem;
    padding-left: 1.5rem;
    margin: 0 auto;
}

@mixin title($size, $color, $align, $letter, $line: normal, $weight: normal) {
    font-family: $font;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    text-align: $align;
    letter-spacing: $letter;
    line-height: $line;
}

@mixin subTitle($size, $color, $align, $mb) {
    font-family: $font;
    font-size: $size;
    color: $color;
    text-align: $align;
    margin-bottom: $mb;
}

@mixin btn(
    $width: auto,
    $height: 37px,
    $color: $color-white,
    $bgColor: $chatams-blue,
    $border: 0px solid transparent,
    $align: center,
    $padding: 0 25px,
    $fontWeight: normal,
    $f-size: 14px
) {
    width: $width;
    min-width: 130px;
    height: $height;
    color: $color;
    font-weight: $fontWeight;
    font-family: $font;
    font-size: $f-size;
    background-color: $bgColor;
    text-align: $align;
    padding: $padding;
    border: $border;
    cursor: pointer;
    outline: none;
    display: inline-block;
    transition: 0.3s ease all;
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

@mixin sticky($top) {
    position: sticky;
    top: $top;
}

@mixin carousel($height: 325px) {
    height: $height;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 2rem;
    position: relative;
    @media only screen and (min-width: $media-small-land) {
        margin-bottom: 0;
    }
    .carousel {
        height: 100%;
        .carousel-indicators {
            margin-bottom: 1rem;
            .active {
                background-color: $color-green;
            }
            li {
                color: $color-orange;
                width: 25px;
                height: 2px;
                margin-left: 0 !important;
                background-color: $color-white;
                opacity: 1;
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            opacity: 1;
        }

        .carousel-control.right:hover,
        .carousel-control-next:hover {
            background: none;
        }

        .carousel-control.left:hover,
        .carousel-control-prev:hover {
            background: none;
        }
        .slider-carousel-wrapper {
            .slider-carousel-button {
                cursor: pointer;
                border-radius: 50%;
                color: $color-white;

                span {
                    font-size: 24px;
                }
            }
        }

        .carousel-inner {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 13px;
            box-shadow: 2px 2px 8px 0 $color-gray-clear;

            .carousel-item {
                border-radius: 13px;
                img {
                    height: $height;
                    object-fit: cover;
                    width: 100%;
                }
                .empty-img {
                    @include flexbox(100%, row, center, center);
                }
            }

            .carousel .carousel-inner .item,
            .carousel .carousel-inner .carousel-item {
                height: 100%;
                overflow: hidden;
            }
        }
    }
}

@mixin inputLabel($width, $color, $fontSize, $margin, $display) {
    width: $width;
    color: $color;
    font-size: $fontSize;
    margin: $margin;
    display: $display;
    font-family: $font;
}

@mixin inputForm($width, $height, $bg, $color, $border, $padding) {
    width: $width;
    height: $height;
    background-color: $bg;
    color: $color;
    border: $border;
    padding: $padding;
    box-sizing: border-box;
    font-family: $font;
    &:focus {
        outline: none;
    }
}

@mixin icon($color, $bgColor, $pointer) {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bgColor;
    color: $color;
    font-size: 14px;
    margin-left: 0.5rem;
    cursor: $pointer;
    &:fist-child {
        margin-left: 0;
    }
}

@mixin comboBox($width: auto) {
    width: $width;
    [class*="-control"] {
        height: 30px;
        min-height: 30px;
        border-radius: 0;
        border-color: $color-gray-clear;
        box-shadow: none;
        cursor: pointer;
        font-family: "Urbanist";

        & > [class*="css-"] {
            &:last-of-type {
                background-color: $color-orange;
                height: 30px;
                width: 30px;
                position: absolute;
                top: -1px;
                right: -1px;
                [class*="-indicatorSeparator"] {
                    display: none;
                }
                [class*="-indicatorContainer"] {
                    padding: 0;
                    left: 4px;
                    position: absolute;
                    color: $color-white;
                }
            }
        }
        & > [class*="css-"] {
            &:last-of-type {
                background-color: $color-orange;
                height: 30px;
                width: 30px;
                position: absolute;
                top: -1px;
                right: -1px;
                [class*="-indicatorSeparator"] {
                    display: none;
                }
                [class*="-indicatorContainer"] {
                    padding: 0;
                    left: 4px;
                    position: absolute;
                    color: $color-white;
                }
            }
        }
    }
}

/*  Grid */
/*Mixin Grid*/
@mixin gridTableWrapper {
    max-width: 100%;
    // @media screen and (max-width: $media-x-large) {
    //     overflow-x: scroll;
    // }
    .export-excel-wrapper {
        @include flexbox(100%, row, flex-start, center);
        margin-bottom: 25px;
        .export-excel-button {
            width: 100%;
        }
    }
}

@mixin gridTable() {
    margin: 1rem auto;
    font-family: $font !important;
    color: $chatams-blue;
    border: none;
    //border-radius: 8px 8px 8px 8px;
    //box-shadow: 0px 0px 8px 0 $color-gray-clear;
    // @media screen and (max-width: $media-x-large) {
    //     width: 1000px !important;
    // } 
    .e-toolbar {
        display: none;
    }

    .e.row {
        color: $color-text;
    }
    .e-gridheader tr th {
        border-bottom: 1px solid $gallery;
        border-top: none;
    }
    .e-gridheader {
        border-top: none !important;
        border-bottom: none !important;
        .e-headercontent {
            .e-rowdragheader,
            .e-grouptopleftcell {
                background-color: $color-primary-variant2;
            }
        }
    }
    .e-groupcaption {
        background-color: $color-blue-clear;
        font-size: 14px;
        color: white;
        border-top: 1px solid white;
    }

    .e-recordpluscollapse {
        background-color: $color-blue-clear;
        color: white;
        border-top: 1px solid white;
        .e-icons {
            padding-top: 20px;
            color: white;
        }
    }

    .e-recordplusexpand {
        background-color: $color-blue-clear;
        color: white;
        border-top: 1px solid white;
        .e-icons {
            padding-top: 16px;
            color: white;
        }
    }

    .e-rowdragdrop {
        .e-icons {
            padding-top: 16px;
        }
    }

    .e-row {
        .e-icons:not(.e-check) {
            padding-top: 50%;
        }
        &.is-no-active {
            background-color: $monza-light;
        }
    }

    .e-indentcell {
        background-color: $color-blue-clear;
        color: white;
    }

    table thead th {
        font-family: $font;
        &[class*="e-headercell"] {
            vertical-align: middle;
            background-color: $white;
        }
        // .e-checkbox-wrapper .e-frame.e-check,
        // .e-css.e-checkbox-wrapper .e-frame.e-check {
        //     font-weight: normal;
        //     font-family: "e-icons";
        //     font-size: 14px;
        // }
        .e-headertext {
            color: $mine-shaft;
            font-weight: bold;
            font-family: $font;
            font-size: 14px;
        }
    }
    table tbody tr:nth-child(odd) {
        font-family: $font;
    }
    table tbody tr td[class^="e-rowcell e-selectionbackground e-active"],
    table tbody tr td[class^="e-rowcell e-focus e-selectionbackground e-active"] {
        background: initial;
        color: #333;
        font-family: $font;
    }
    table tbody tr td[class*="e-active"] {
        background: initial !important;
    }
    form table tbody tr td span[class^="e-input-group e-control-wrapper e-input-focus"] {
        border-color: $color-orange !important;
        font-family: $font;
    }
    button {
        font-family: $font;
    }
    a[class^="e-link e-numericitem e-spacing e-currentitem e-active"] {
        background: $chatams-blue;
        color: $color-white;
        font-family: $font;
    }
    .action-template {
        @include flexbox(100%, row, flex-end, flex-end, nowrap);
        color: $chatams-blue;
        cursor: pointer;
        span {
            color: $chatams-blue;
            margin-left: 5px;
            &:first-of-type{
                margin-left: 0;
            }
            &:hover{
                color: $monza-normal;
            }
            &:active{
                color: $monza-dark;
            }
        }
        .btn-activate {
            &:hover{
                color: $color-green;
            }
            &:active{
                color: $color-green;
            }
        }
    }
}

@mixin gridFont() {
    [class^="e-control"] {
        font-family: $font;
        [class^="e-tbar-btn-text"],
        [class^="e-parentmsgbar"] {
            font-family: $font;
        }
    }
}

@mixin hr($color) {
    margin: 2rem 0;
    background: transparent;
    border-top: 0.1rem solid $color;
}

@mixin tab-button() {
    font-size: 18px;
    font-weight: 600;
    color: $color-text;
    border: none;
    border-bottom: 2px solid $color-white-clear;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease all;
    &:hover {
        border-color: $color-secondary;
        font-weight: 600;
    }
    &.active {
        border-color: $color-primary;
        font-weight: 600;
    }
    &:first-of-type {
        margin-left: 0;
    }
}

@mixin form-row {
    display: flex;
    flex-wrap: wrap;
}

@mixin form-wrapper {
    margin: 5px;
}

@mixin margin-r($size) {
    margin-right: $size;
}

@mixin margin-l($size) {
    margin-left: $size;
}

@mixin img-wrapper {
    position: relative;
    box-sizing: border-box;
    max-width: 170px;
    max-height: 170px;
    min-width: 170px;
    min-height: 170px;
    width: 170px;
    height: 170px;
    border-radius: 8px;
    margin: 5px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
        border: 1px solid rgba(143, 143, 143, 0.5);
        border-radius: 8px;
        &:hover {
            box-shadow: 0 4px 6px 0 rgba(143, 143, 143, 0.5);
            border: 2px solid rgba(143, 143, 143, 0.5);
        }
    }
}

@mixin img-wrapper-horizontal {
    position: relative;
    box-sizing: border-box;
    max-width: 300px;
    max-height: 75px;
    min-width: 300px;
    min-height: 75px;
    width: 300px;
    height: 75px;
    border-radius: 8px;
    margin: 5px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
        border: 1px solid rgba(143, 143, 143, 0.5);
        border-radius: 8px;
        &:hover {
            box-shadow: 0 4px 6px 0 rgba(143, 143, 143, 0.5);
            border: 2px solid rgba(143, 143, 143, 0.5);
        }
    }
}

@mixin round-borders($tl, $tr, $br, $bl) {
    -webkit-border-radius: $tl $tr $br $bl;
    -moz-border-radius: $tl $tr $br $bl;
    border-radius: $tl $tr $br $bl;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

@mixin date-wrapper() {
    width: 100%;

    [class*="e-date-wrapper"] {
        height: 30px;
        min-height: 30px;
        border: 1px solid !important;
        border-radius: 45px !important;
        border-color: $color-gray-clear !important;
        box-shadow: none;
        cursor: pointer;
        font-family: $font !important;
        font-size: 14px !important;
        margin: 0px !important;
        &::after,
        &::before {
            background: transparent !important;
        }
        & > input {
            padding-left: 0.5rem !important;
        }

        .e-datepicker {
            padding: 3px 0;
        }

        [class*="e-date-icon"] {
            color: $color-secondary !important;
            margin-top: 3px !important;
            margin-right: 0.5rem !important;
        }

        &.e-input-focus {
            border-width: 2px !important;
            border-color: $color-secondary !important;
        }

        .e-clear-icon {
            display: none !important;
        }
    }

    .e-content.e-month {
        .e-schedule-table {
            tbody {
                .e-week-number {
                    color: $color-secondary !important;
                    font-size: 0.7rem;
                }

                .e-cell {
                    &.e-selected {
                        .e-day {
                            background-color: $color-secondary;
                            border: none;
                            box-shadow: inset 0 0 0 2px #fff;
                            color: #fff;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }

    [class*="input-error"] {
        border: 2px solid $color-red !important;
        [class*="e-date-icon"] {
            color: $color-red !important;
        }
    }
}
