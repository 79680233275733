@import "@backoffice/scss/index.scss";
.header {
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    background-color: $color-header-bg;
    color: $color-header;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 1rem 0;

    .header-wrapper {
        @include flexbox(100%, row, space-between, flex-start);
        flex-wrap: nowrap;
        .img-logo {
            padding: 5px 10px 5px 20px;
            height: 50px;
            max-height: 50px;
            max-width: 320px;
            cursor: pointer;
        }

        .right-menu-wrapper {
            @include flexbox(100%, row, flex-end, center);
            padding: 10px 15px;
            .translationButton-wrapper {
                margin-right: 20px;
            }
        }

        .user-wrapper {
            @include flexbox(auto, row, center, center);
            font-weight: bolder;
            .icon {
                margin-right: 5px;
                position: relative;
                top: 2px;
                ion-icon {
                    font-size: 25px;
                }
            }
            .profile-img-wrapper {
                @include img-wrapper();
                max-width: 30px;
                max-height: 30px;
                min-width: 30px;
                min-height: 30px;
                width: 30px;
                height: 30px;

                img {
                    cursor: pointer;
                    &:hover {
                        border: none;
                    }
                }
            }

            .user-name {
                @media screen and (max-width: $media-x-small) {
                    display: none;
                }
            }
        }
        a {
            color: $color-menu;
            text-decoration: none !important;
        }
    }
}
