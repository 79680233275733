@import "../../../scss/index.scss";

.checkbox {
  margin-bottom: 1em;
  .link-wrapper{
    a{
      color: $chatams-blue;
      text-decoration: underline;
      &:hover{
        color: $monza-normal;
      }
      &:active{
        color: $monza-dark;
      }
    }
  }
  .inputCheck {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-family: $font;
      color: $color-text; 
      font-size: 14px;

      & + span {         
        font-size: 14px;
      }
    }

    // Box.
    & + label:before {
      content: "";
      display: inline-block;
      vertical-align: bottom;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background: $gallery;
      border-radius: 2px; 
      //border: 1px solid $chatams-blue;
    }

    // Box focus
    &:focus + label:before {
      border: 2px solid $chatams-blue;
    }

    // Box checked
    &:checked + label:before {
      background: $chatams-blue;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 8px;
      background: $color-white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $color-white, 4px 0 0 $color-white, 4px -2px 0 $color-white, 4px -4px 0 $color-white,
        4px -6px 0 $color-white, 4px -8px 0 $color-white;
      transform: rotate(45deg);
    }
  }

  &.isDisabled {
    opacity: 1;

    .inputCheck {
      // Box.
      & + label:before {
        background: $color-disabled;
      }
      // Box checked
      &:checked + label:before {
        background: $color-disabled;
      }
    }
  }
}
