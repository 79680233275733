@import "@backoffice/scss/index.scss";
.users {
    width: 100%;
    margin-top: 2rem;
    .add-button {
        margin-top: 8px;
        width: 100%;
    }
    .filters-container {
        margin-top: 2rem;
        .free-search-wrapper{
            width: 250px;
        }
        .fields-search-wrapper{
            width: 250px;
            display: inline-block;
            margin-right: 1rem;
            &:last-of-type{
                margin-right: 0;
            }
            .checkbox {
                label {
                    font-size: 14px;
                }
            }
        }
    }
    .add-user-wrapper{
        @include flexbox(100%, row, flex-end, center);
    }

    .icon-star {
        color: $color-orange;
    }
}
