@import "../../../scss/index.scss";

.auditor-companies {
    width: 100%;
    margin-top: 2rem;
    .add-button {
        margin-top: 8px;
        width: 100%;
    }
    .filters-container {
        margin-top: 2rem;
        .free-search-wrapper{
            width: 250px;
        }
        .fields-search-wrapper{
            width: 250px;
            display: inline-block;
            margin-right: 1rem;
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    .add-user-wrapper{
        @include flexbox(100%, row, flex-end, center);
    }
    .request-grid {
        a {
            text-decoration: none;
        }

        .document-template {
            @include flexbox(100%, row, flex-start, center, wrap);
            gap: 10px;

            .button-upload {
                label {
                    width: fit-content;
                }

                .inputfile{
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                }
            }
        }
    }

    .material-icons-round {
        &.deactivated {
            color: $monza-dark;
        }

        &.activated {
            color: $color-green;
        }
    }
}