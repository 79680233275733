@import "@backoffice/scss/index.scss";
.companies {
    width: 100%;
    margin-top: 2rem;
    .add-button {
        margin-top: 8px;
        width: 100%;
    }
    .filters-container {
        margin-top: 2rem;
        .free-search-wrapper{
            width: 100%;
            @media screen and (min-width: $media-medium) {
                width: 250px; 
            }
        } 
        .fields-search-wrapper{
            width: 100%;
            display: inline-block;
            @media screen and (min-width: $media-medium) {
                margin-right: 1rem;
                width: 250px; 
            }
            &:last-of-type{
                @include flexbox(auto, row, flex-start, center);
                height: 78px;
                margin-right: 0;
            }
            .btn-group{
                button{
                    margin-top: 12px;
                }
            }
        }
        .flex-container{
            @include flexbox(auto, row, flex-start, flex-start);
        }
        .filter-dates{
            @include flexbox(100%, row, space-between, flex-start);
            @media screen and (min-width: $media-medium) {
                width: 250px; 
            }
            .filter-input-label{
                margin: 3px 6px 5px 0;
                font-size: 12px;
            }
            .row-wrapper{
                @include flexbox(100%, row, space-between, flex-start);
            }
            .date-wrapper {
                width: 49%;
                @media screen and (min-width: $media-medium) {
                    width: 120; 
                }
            }
        }
    }

    //grid templates
    .seal-template, .type-template{
        @include flexbox(auto, row, flex-start, center);
        span{
            &:first-of-type{
                margin-right: .5rem;
            }
        }
        .icon-custom {
            color: $white;
            padding: 3px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            text-align: center;
        }
    }
    .questionnaire-template{
        span{
            @include flexbox(auto, row, center, center);
            max-width: 100px;
            height: 24px;
            border-radius: 15px;
        }
    }
    .date-wrapper [class*=e-date-wrapper] .e-clear-icon {
        display: none!important;
    }
    .text-silver {
        color: $silver;
    }
}

