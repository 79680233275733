@import "@backoffice/scss/index.scss";

.paginator {
    @include flexbox($direction: column, $j: space-between, $a: center, $wr: nowrap);
    gap: 0.5rem;
    font-size: $font-size-sm;

    li > span {
        font-size: $font-size-sm;
    }

    @media screen and (min-width: $media-medium-land) {
        flex-direction: row;
    }

    &-btns-wrapper {
        @include flexbox($j: center, $a: center, $wr: nowrap, $width: 100%);
        gap: 0.5rem;

        @media screen and (min-width: $media-medium-land) {
            justify-content: flex-start;
        }
    
        .paginator {
            &-prev-btns,
            &-next-btns {
                @include flexbox($width: auto, $j: center, $a: center, $wr: nowrap);
                gap: 0.5rem;
        
                &-start,
                &-prev,
                &-end,
                &-next {
                    @include flexbox($j: center, $a: center, $wr: nowrap, $width: 100%);
                    cursor: pointer;
                    border-radius: 50%;
                    min-width: 30px;
                    height: 30px;
        
                    &:not(.btn-disable) {
                        &:hover {
                            color: $color-white;
                            background-color: $chatams-blue;
                            border-radius: 50%;
                        }
                    }
        
                    &.btn-disable {
                        cursor: inherit;
                        color: $color-gray-clear;
                    }
                }
            }
        
            &-pages-btn{
                height: 33px;

                ul {
                    @include flexbox($j: center, $a: center, $wr: nowrap, $width: 100%);
                    gap: 0.5rem;
                    height: 100%;
                    li {
                        @include flexbox($j: center, $a: center, $wr: nowrap, $width: 100%);
                        height: 100%;
                        border-radius: 50%;
        
                        span {
                            padding: 0.5rem;
                            border-radius: 50%;
                            min-width: 30px;
                            text-align: center;
                        }
        
                        &.active-page {
                            color: $color-white;
                            background-color: $chatams-blue;
                        }
        
                        &.no-active-page {
                            color: $color-disabled;
                            background-color: $color-white-clear;
        
                            &:hover {
                                cursor: pointer;
                                color: $color-white;
                                background-color: $chatams-blue;
                            }
                        }
                    }
                }
            }
        }
    }
    .items-count{
        @include flexbox(100%, column, center, center, $wr: nowrap);

        @media screen and (min-width: $media-medium-land) {
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}